import React, {useLayoutEffect} from 'react';
import  cl from './AboutStack.module.css'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const AboutStack = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".blog",
                scrub: true,
                start: "-70% bottom",
                end: "-30% 50%",
            }
        })

        tl.from(".blog", {
            y: 500,
        }).to(".blog",
            {
                y:0,
            }
        );
    }, []);
    return (
        <div className={cl.container}>
            <h2 className={cl.name}>Технологический стек</h2>
            <div className={"blog"}>
                <div className={cl.mainTech}>
                    <div>
                        <h3 className={cl.head}>
                            Дизайн
                        </h3>
                        <div className={cl.list}>
                            <ul>
                                <li>FIGMA</li>
                                <li>BLENDER</li>
                                <li>SKETCH</li>
                                <li>GOOGLE FONTS</li>
                                <li>WORDPRESS</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3 className={cl.head}>FrontEnd</h3>
                        <div className={cl.list}>
                            <ul>
                                <li>HTML&CSS</li>
                                <li>JS, TYPESCRIPT</li>
                                <li>REACT, REDUX</li>
                                <li>BOOTSTRAP, GRID, FLEX</li>
                                <li>SASS, LESS</li>
                                <li>АДАПТИВНАЯ ВЕРСКА</li>
                                <li>КРОССБРАУЗЕРНАЯ ВЕРСКА</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3 className={cl.head}>BackEnd</h3>
                        <div className={cl.list}>
                            <ul>
                                <li>DJANGO</li>
                                <li>FLASK</li>
                                <li>EXPRESS(JS)</li>
                                <li>MYSQL</li>
                                <li>POSTGRESQL</li>
                                <li>DJANGO ORM</li>
                                <li>SPRING(JAVA)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={cl.someTech}>
                    <div>Другие технологии:</div> Яндекс.Директ, MidJourney, ChatGPT
                </div>
            </div>
        </div>
    );
};

export default AboutStack;