import React, {useLayoutEffect} from 'react';
import cl from './SerTypes.module.css'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const SerTypes = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl.from(".bl", {
            y: 500,
        }).to(".bl",
            {
                y:0,
            }
        );
        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl1",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl1.from(".bl1", {
            y: 500,
        }).to(".bl1",
            {
                y:0,
            }
        );
        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl2",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl2.from(".bl2", {
            y: 500,
        }).to(".bl2",
            {
                y:0,
            }
        );
        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl3",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl3.from(".bl3", {
            y: 500,
        }).to(".bl3",
            {
                y:0,
            }
        );
        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl4",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl4.from(".bl4", {
            y: 500,
        }).to(".bl4",
            {
                y:0,
            }
        );
        const tl5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl5",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl5.from(".bl5", {
            y: 500,
        }).to(".bl5",
            {
                y:0,
            }
        );
        const tl6 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl6",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl6.from(".bl6", {
            y: 500,
        }).to(".bl6",
            {
                y:0,
            }
        );
        const tl7 = gsap.timeline({
            scrollTrigger: {
                trigger: ".bl7",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        tl7.from(".bl7", {
            y: 500,
        }).to(".bl7",
            {
                y:0,
            }
        );


    }, []);
    return (
        <div className={cl.container}>
            <div className={"bl"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Корпоративные сайты
                    </h3>
                    <div className={cl.number}>
01
                    </div>
                </div>
                <p className={cl.description}>
                    Представляют компанию, её миссию, услуги. Примеры: IBM, Siemens.
                </p>
            </div>
            <div className={"bl1"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Интернет-магазины
                    </h3>
                    <div className={cl.number}>
02
                    </div>
                </div>
                <p className={cl.description}>
                    Для электронной коммерции, продажи товаров и услуг. Примеры: Amazon, eBay.
                </p>
            </div>
            <div className={"bl2"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Лендинги
                    </h3>
                    <div className={cl.number}>
03
                    </div>
                </div>
                <p className={cl.description}>
                    Одностраничные сайты для продвижения определенной цели. Примеры: продуктовые страницы на Kickstarter.
                </p>
            </div>
            <div className={"bl3"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Блоги
                    </h3>
                    <div className={cl.number}>
04
                    </div>
                </div>
                <p className={cl.description}>
                    Регулярно обновляемые статьи или новости. Примеры: TechCrunch, Mashable.
                </p>
            </div>
            <div className={"bl4"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Портфолио
                    </h3>
                    <div className={cl.number}>
05
                    </div>
                </div>
                <p className={cl.description}>
                    Витрины работ креативных профессионалов. Примеры: Behance, Dribbble.
                </p>
            </div>
            <div className={"bl5"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Информационные порталы
                    </h3>
                    <div className={cl.number}>
06
                    </div>
                </div>
                <p className={cl.description}>
                    Большие сайты с новостями и ресурсами. Примеры: CNN, BBC.
                </p>
            </div>
            <div className={"bl6"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Форумы
                    </h3>
                    <div className={cl.number}>
07
                    </div>
                </div>
                <p className={cl.description}>
                    Для обсуждений и обмена сообщениями. Примеры: Reddit, Quora.
                </p>
            </div>
            <div className={"bl7"}>
                <div className={cl.naming}>
                    <h3 className={cl.name}>
                        Социальные сети
                    </h3>
                    <div className={cl.number}>
08
                    </div>
                </div>
                <p className={cl.description}>
                    Для общения, обмена контентом. Примеры: Facebook, Twitter.
                </p>
            </div>
        </div>
    );
};

export default SerTypes;