import React, {useLayoutEffect} from 'react';
import photo from '../../../images/pc.png'
import cl from  './Description.module.css'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Description = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".imagination",
                scrub: true,
                start: "10% bottom",
                end: "10% 50%",
            }
        })

        tl.from(".imagination", {
            y: 700,
        }).to(".imagination",
            {
                y:0,
            }
        );
        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".right",
                scrub: true,
                start: "-5% bottom",
                end: "-20% 50%",
            }
        })

        au.from(".right", {
            x: 900,
        }).to(".right",
            {
                x:0,
            }
        );


    }, []);
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={cl.container}>
            <div className={cl.left}>
                <img src={photo} alt="" className={'imagination'} onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
            <div className={cl.rightCont}>
                <div className={"right"}>
                    Вашему вниманию представляется раздел, посвященный <div>нашей</div> <div> команде</div> <div> профессионалов</div>. Но указаны здесь не все,
                    а лишь <div>самые ценные</div> сотрудники нашей компании.
                </div>
            </div>

        </div>
    );
};

export default Description;