import {React, useLayoutEffect} from 'react';
import cl from '../PortMain.module.css'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const StackPart = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".stack",
                scrub: true,
                start: "-50% bottom",
                end: "-30% 50%",
            }
        })

        tl.from(".stack", {
            y: 500,
        }).to(".stack",
            {
                y:0,
            }
        );
    }, []);
    return (
        <div className={"stack"} style={{transition: "all, 1s"}}>
            <div className={cl.name}>
                Технологический стек
            </div>
            <div className={cl.mainTech}>
                <div className={cl.design}>
                    <div className={cl.head}>
                        Дизайн
                    </div>
                    <div className={cl.list}>
                        <ul>
                            <li>FIGMA</li>
                            <li>BLENDER</li>
                            <li>SKETCH</li>
                            <li>GOOGLE FONTS</li>
                            <li>WORDPRESS</li>
                        </ul>
                    </div>
                </div>
                <div className={cl.front}>
                    <div className={cl.head}>FrontEnd</div>
                    <div className={cl.list}>
                        <ul>
                            <li>HTML&CSS</li>
                            <li>JS, TYPESCRIPT</li>
                            <li>REACT, REDUX</li>
                            <li>BOOTSTRAP, GRID, FLEX</li>
                            <li>SASS, LESS</li>
                            <li>Адаптивная и мультибраузерная верстка</li>
                        </ul>
                    </div>
                </div>
                <div className={cl.back}>
                    <div className={cl.head}>BackEnd</div>
                    <div className={cl.list}>
                        <ul>
                            <li>DJANGO</li>
                            <li>FLASK</li>
                            <li>EXPRESS(JS)</li>
                            <li>MYSQL</li>
                            <li>POSTGRESQL</li>
                            <li>DJANGO ORM</li>
                            <li>SPRING(JAVA)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={cl.someTech}>
                <div>Другие технологии:</div> Яндекс.Директ, MidJourney, ChatGPT
            </div>
        </div>
    );
};

export default StackPart;