import React from 'react';
import cl from './MyTech.module.css'
const MyTech = () => {
    return (
        <div className={cl.container}>
            <div>
            FIGMA, HTML&CSS, DJANGO, BLENDER, JS, TYPESCRIPT, FLASK, SKETCH, MidJourney, ChatGPT, REACT, REDUX, EXPRESS(JS), WORDPRESS, Яндекс.Директ, BOOTSTRAP, GRID, FLEX, MYSQL, SASS, LESS, POSTGRESQL, DJANGO ORM, SPRING(JAVA), FIGMA, HTML&CSS, DJANGO, BLENDER, JS, TYPESCRIPT, FLASK, SKETCH, MidJourney, ChatGPT, REACT, REDUX, EXPRESS(JS), WORDPRESS, Яндекс.Директ, BOOTSTRAP, GRID, FLEX, MYSQL, SASS, LESS, POSTGRESQL, DJANGO ORM, SPRING(JAVA)
            </div>
        </div>
    );
};

export default MyTech;