import React from 'react';
import MyHeader from "../components/header/MyHeader";
import IntroAbout from "../components/aboutPage/introAbout/IntroAbout";
import AboutMission from "../components/aboutPage/mission/AboutMission";
import AboutTeam from "../components/aboutPage/team/AboutTeam";
import MyProcess from "../components/process/MyProcess";
import MyFooter from "../components/footer/MyFooter";
import AboutStack from "../components/aboutPage/stack/AboutStack";
import MainWhy from "../components/mainPage/why/MainWhy";
import CustomCursor from "../components/CustomCursor/CustomCursor";
import Loading from "../components/loading/Loading";
import {usePageLoaded} from "../components/usepageloaded/usePageLoaded";
import { Helmet } from "react-helmet";
const About = () => {
    const isLoaded = usePageLoaded();

    return (
        <>
            <Helmet>

                <title>Веб-дизайн и маркетинг | создание сайтов GoTem_web</title>

                <meta name="description" content="GoTem_web: создание сайтов под ключ недорого, быстро и качественно." />

                <link rel="canonical" href="https://gotem-web.ru/about" />

                <link rel="icon" type="image/png" href="../../public/favicon.png"/>
            </Helmet>
            {isLoaded ? (
                <div style={{
                    overflow: "hidden"
                }}>

                    <CustomCursor/>
                    <MyHeader/>
                    <IntroAbout/>
                    <AboutMission/>
                    <AboutTeam/>
                    <MainWhy/>
                    <MyProcess/>
                    <AboutStack/>
                    <MyFooter/>
                </div>
            ) : (
                <Loading/>
            )}
        </>
    );
};

export default About;