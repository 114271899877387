import React, { useEffect, useRef, useState } from "react";

const ScrollingText = ({text, color1, color2}) => {
    const containerRef = useRef(null);
    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const { top } = containerRef.current.getBoundingClientRect();
                const scrollPosition = window.innerHeight - top;

                if (scrollPosition > 0) {
                    const newIndex = Math.floor(scrollPosition / 15);
                    setVisibleIndex(newIndex);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div
            ref={containerRef}
            style={{
                whiteSpace: "pre-wrap",
                width: '96vw',
                padding: '2vw',
                textAlign: 'center'
            }}
        >
            {text.split("").map((char, index) => (
                <span
                    key={index}
                    style={{
                        display: "inline-block",
                        color: visibleIndex > index ? `${color2}` : `${color1}`,
                    }}
                >
          {char}
        </span>
            ))}
            <br/><br/>
        </div>
    );
};

export default ScrollingText;
