import React, {useLayoutEffect} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import cl from './MainWhy.module.css'
import MyName from "../../name/MyName";

const MainWhy = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".info",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl.from(".info", {
            y: 500,
        }).to(".info",
            {
                y: 0,
            }
        );

        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: ".info1",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl1.from(".info1", {
            y: 500,
        }).to(".info1",
            {
                y: 0,
            }
        );
        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".info2",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl2.from(".info2", {
            y: 500,
        }).to(".info2",
            {
                y: 0,
            }
        );
        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".info3",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl3.from(".info3", {
            y: 500,
        }).to(".info3",
            {
                y: 0,
            }
        );
        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".info4",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl4.from(".info4", {
            y: 500,
        }).to(".info4",
            {
                y: 0,
            }
        );
        const tl5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".info5",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl5.from(".info5", {
            y: 500,
        }).to(".info5",
            {
                y: 0,
            }
        );
    }, []);


    return (
        <div className={cl.container}>
            <MyName name={'Почему мы?'}/>
            <div className={cl.infoContainer}>
                <div className={"info"} style={{
                    transition:"all, 0.5s"
                }}>
                    <div className={cl.naming}>
                        <h2 className={cl.name}>
                            Особый подход
                        </h2>
                        <div className={cl.number}>
                            01
                        </div>
                    </div>
                    <p className={cl.about}>
                        В GoTem_web мы предлагаем решения под ключ, которые идеально отражают ваш бренд и привлекают клиентов. Профессиональная разработка сайтов для бизнеса в Омске позволяет нашим клиентам получать уникальные и эффективные веб-решения.
                    </p>
                </div>
                <div className={"info1"}>
                    <div className={cl.naming}>
                        <h2 className={cl.name}>
                            Выгодные услуги
                        </h2>
                        <div className={cl.number}>
02
                        </div>
                    </div>
                    <p className={cl.about}>
                        Веб-студия GoTem_web предлагает комплексные услуги по веб-дизайну от создания адаптивных сайтов до SEO продвижения и услуг SMM. Наше комплексное предложение (создание сайта под ключ) гарантирует, что вы получаете все необходимые инструменты для успеха в интернете в одном месте.
                    </p>
                </div>
                <div className={"info2"}>
                    <div className={cl.naming}>
                        <h2 className={cl.name}>
Соотношение цена/качество
                        </h2>
                        <div className={cl.number}>
03
                        </div>
                    </div>
                    <p className={cl.about}>
                        GoTem_web предоставляет разработку и продвиение высококачественных сайты в Омске недорого. Благодаря гибкой системе ценообразования и услугам профессионалов, вы получаете значительную отдачу от каждого вложенного рубля.
                    </p>
                </div>
                <div className={"info3"}>
                    <div className={cl.naming}>
                        <h2 className={cl.name}>
                            Богатый опыт и портфолио
                        </h2>
                        <div className={cl.number}>
                            04
                        </div>
                    </div>
                    <p className={cl.about}>
                        Наша веб-студия GoTem_web имеет обширное портфолио и множество положительных отзывов, которые подчеркивают доверие наших клиентов к качеству наших услуг. Благодаря опыту, мы предлагаем создание сайтов любой сложности.
                    </p>
                </div>
                <div className={"info4"}>
                    <div className={cl.naming}>
                        <h2 className={cl.name}>
                            Скорость выполнения
                        </h2>
                        <div className={cl.number}>
                            05
                        </div>
                    </div>
                    <p className={cl.about}>
                        В GoTem_web мы гарантируем быструю разработку как лендингов, так и интернет-магазинов. Эффективное и своевременное выполнение работ позволяет вам быстро запустить или обновить ваш онлайн-бизнес.
                    </p>
                </div>
                <div className={"info5"}>
                    <div className={cl.naming}>
                        <h2 className={cl.name}>
                            Продвижение в интернете
                        </h2>
                        <div className={cl.number}>
                            06
                        </div>
                    </div>
                    <p className={cl.about}>
                        GoTem_web разрабатывает и реализует мощные стратегии продвижения, включая контекстную рекламу и SEO продвижение. Полное продвижение сайтов от SEO до SMM увеличивает видимость вашего сайта и привлекает целевую аудиторию.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MainWhy;