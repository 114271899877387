import React, { useEffect, useRef, useState } from "react";

const ScrollingShadow = ({ text, color1, color2 }) => {
    const containerRef = useRef(null);
    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const { top } = containerRef.current.getBoundingClientRect();
                const scrollPosition = window.innerHeight - top;

                if (scrollPosition > 0) {
                    const newIndex = Math.floor(scrollPosition / 20);
                    setVisibleIndex(newIndex);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div
            ref={containerRef}
            style={{
                whiteSpace: "pre",
                width: '100vw',
                textAlign: 'center'
            }}
        >
            {text.split("").map((char, index) => (
                <span
                    key={index}
                    style={{
                        display: "inline-block",
                        color: color1,
                        textShadow: visibleIndex > index ? `0 0 10px ${color2}` : "none",
                    }}
                >
          {char}
        </span>
            ))}
            <br /><br />
        </div>
    );
};

export default ScrollingShadow;