import React, { useEffect, useState } from 'react';
import './CustomCursor.css';

const CustomCursor = () => {
    const [dotPosition, setDotPosition] = useState({x: -100, y:-100});
    const [circlePosition, setCirclePosition] = useState({x: -100, y:-100});

    useEffect(() => {
        const handleMouseMove = (e) => {
            setDotPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const handleAnimation = () => {
            const distanceX = dotPosition.x - circlePosition.x;
            const distanceY = dotPosition.y - circlePosition.y;
            const distance = distanceX + distanceY;

            if (distance > -34) {
                setCirclePosition(() => ({
                    x: dotPosition.x - 17,
                    y: dotPosition.y -17
                }));
            } else {
                setCirclePosition(dotPosition);
            }

            requestAnimationFrame(handleAnimation);
        };

        handleAnimation();
    }, [dotPosition]);

    return (
        <>
            <div
                className="cursor-circle"
                style={{
                    transition: "all, 0.08s",
                    transform: `translate(${circlePosition.x}px, ${circlePosition.y}px)`
                }}
            />
                <div
                    className="cursor-dot"
                    style={{
                        transform: `translate(${dotPosition.x}px, ${dotPosition.y}px)`
                    }}
                >
                    <div
                        className="cursor"
                    >
                </div>
            </div>
        </>
    );
};

export default CustomCursor;





