import React, {useLayoutEffect} from 'react';
import cl from './ServicesTypes.module.css'
import pc from '../../../images/planet.png'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const ServicesTypes = () => {
    gsap.registerPlugin(ScrollTrigger)
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".img",
                scrub: true,
                start: "-30% bottom",
                end: "-6% 50%",
            }
        })

        tl.from(".img", {
            y: 500,
        }).to(".img",
            {
                y:0,
            }
        );
        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".right",
                scrub: true,
                start: "-40% bottom",
                end: "-6% 50%",
            }
        })

        au.from(".right", {
            x: 900,
        }).to(".right",
            {
                x:0,
            }
        );


    }, []);
    return (
        <div className={cl.container}>
                <div className={cl.left}>
                    <img src={pc} alt="планета для разработки сайтов в Омске от веб-студии" className={'img'} onContextMenu={handleContextMenu} draggable={"false"}/>
                </div>
            <div className={cl.rightCont}>
                <p className={"right"}>
                    В веб-студии <div> GoTem_web </div> мы представляем большое разнообразие типов сайтов. Мы можем
                    создать лендинг, интернет-магазин и любой другой тип сайта.
                    <div className={cl.second}> О них вы можете узнать прямо на нашем сайте.</div>
                </p>
            </div>
        </div>
    );
};

export default ServicesTypes;