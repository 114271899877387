import React from 'react';
import MyHeader from "../../components/header/MyHeader";
import MyFooter from "../../components/footer/MyFooter";
import PORTIntro from "../PORTIntro";
import  cl from '../banana/Banana.module.css';
import credit from '../../portfolio/sammy4.jpg'

const Work2 = () => {
    return (
        <div style={{
            overflow: 'hidden'
        }}>
            <MyHeader/>
            <PORTIntro/>
            <div className={cl.container}>
                <img src={credit} alt=""/>
            </div>
            <MyFooter/>
        </div>
    );
};

export default Work2;