import React, { useRef, useEffect } from 'react';
import honey from '../../images/hooooney.png';
import cl from './BeeWorldPhone.module.css';
import BeeBackPhone from "./BeeBackPhone";

const BeeWorldPhone = () => {

    const handleMouseMove = (e) => {
        const offsetX = (window.innerWidth / 2 - e.clientX) / 15;
        const offsetY = (window.innerHeight / 2 - e.clientY) / 15;
    };

    return (
        <div onMouseMove={handleMouseMove} style={{
            position: 'relative',
            zIndex: '0'
        }}>
            <BeeBackPhone/>
            <div className={cl.titles}>
                <h1 className={cl.title}>
                    Трудолюбие и сладкий успех
                </h1>
                <h2 className={cl.subTitle}>
                    Работаем как пчёлки ради вас, <br /> создавая произведения искусства, а не сайты
                </h2>
            </div>
            <div className={cl.honeyContainer}>
                {[ 'honey3', 'honey4'].map((honeyClass, idx) => (
                    <img
                        key={idx}
                        src={honey}
                        alt=""
                        className={cl[honeyClass]}
                    />
                ))}
            </div>
        </div>
    );
};

export default BeeWorldPhone;
