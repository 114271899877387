import React, {useState} from 'react';
import cl from './MyQuest.module.css'
import {CSSTransition} from "react-transition-group";
import '../../../App.css'

const MyQuest = (props) => {
const [display, setDisplay] = useState(true);
const [height,setHeight] = useState('12vh')
    function view() {
        setDisplay(!display)
        if (height==='12vh'){
            setHeight('50vh')
        }else{
            setHeight('12vh')
        }
    }
    return (
            <div className={cl.container} onClick={view}>
                    <div className={cl.quest} style={{height:height}}>
                        <h2 style={{
                            fontWeight: "normal",
                        }}>
                            {props.quest}
                        </h2>
                        <div className={cl.button} onClick={view}>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12H20M12 4V20" stroke="#5d5d5d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </div>
                        <CSSTransition in={!display} classNames={'alert'} unmountOnExit timeout={1000}>
                            <p className={cl.answer}>
                                {props.answer}
                            </p>
                        </CSSTransition>
                    </div>
            </div>
    );
};

export default MyQuest;