import React, { useState, useEffect, useRef } from 'react';
import cl from "../pages/Main.module.css";
import arm from "../images/arm.png";
import clock from "../images/clock.png";
import rockets from "../images/rocket.png";
import spy from "../images/spy.png";
import chef from "../images/chef.png";
import prize from "../images/prize.png";
import headphones from "../images/headphones.png";
import note from "../images/note.png";
import {motion} from "framer-motion";

const ScrollOverlay = () => {
    const [isFixed, setIsFixed] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(0);
    const blockRef = useRef(null);
    const reviewRefs = useRef([]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [virgin, setVirgin] = useState(true);
    const [virginNew, setVirginNew] = useState(false);

    const [scroll1, setScroll1] = useState(true);
    const [scroll2, setScroll2] = useState(true);

    const handleScroll = () => {
        const block = blockRef.current;
        if (block) {
            const blockRect = block.getBoundingClientRect();
            if (blockRect.top <= 0 && !isFixed && virgin) {
                setIsFixed(true)
            }
            if (blockRect.bottom >= screenHeight && !isFixed && virginNew){
                setIsFixed(true)
            }
        }
    }
   useEffect(
       () => {
           if (scrollOffset < -screenHeight * 4.2) {
               setIsFixed(false)
               setScrollOffset(-screenHeight * 4.2)
               setVirginNew(true)
           } else if (scrollOffset > 25) {
               setIsFixed(false);
               setScrollOffset(25)
               setVirgin(true)
               setVirginNew(false)
           }
       }, [scrollOffset]
   )
    const onScrollFixedContent = (e) => {
        if (isFixed) {
            const delta = e.deltaY;
            setScrollOffset(prevOffset => prevOffset - delta / 1.25);
            setVirgin(false)
            }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('wheel', onScrollFixedContent);


        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('wheel', onScrollFixedContent);
            document.body.style.overflow = 'auto';
        };
    }, [isFixed]);

    useEffect(() => {
        if (isFixed) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isFixed]);






    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const fontSize =20;
        const columns = Math.floor(canvas.width / fontSize);
        const drops = Array(columns).fill(1);

        ctx.font = `${fontSize}px monospace`;

        const targetDisappearTime = 500; // 5 seconds
        const frameRate = 50; // обновляем 20 раз в секунду (1000ms / 50ms = 20fps)
        const totalFrames = targetDisappearTime / (1000 / frameRate);
        const alphaDecay = 1 / totalFrames; // сколько прозрачности добавлять на каждом кадре


        const fadeGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        fadeGradient.addColorStop(0, 'rgba(252, 124, 51, 0.05)'); // начало градиента
        fadeGradient.addColorStop(1, 'rgba(79, 41, 129, 0.05)');

        const drawMatrix = () => {
            // Вычисление нового альфа-значения
            ctx.fillStyle = fadeGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = '#212121' // Оранжевый или фиолетовый
            drops.forEach((yPos, index) => {
                const text = String.fromCharCode(33 + Math.random() * 94);
                const xPos = index * fontSize;
                ctx.fillText(text, xPos, yPos * fontSize);

                if (yPos * fontSize > canvas.height && Math.random() > 0.975) {
                    drops[index] = 0;
                }

                drops[index]++;
            });
        };

        const intervalId = setInterval(drawMatrix, frameRate);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <div className={cl.advantages}
                 ref={blockRef}
                 style={{
                     position: isFixed ? 'fixed' : 'relative',
                     top: isFixed ? 0 : 'auto',
                     zIndex: 2
                 }}
            >
                <div>
                    <div className={cl.left}>
                        <motion.img
                            src={arm}
                            alt=""
                            className={cl.advArm}
                            initial={{x: -300, opacity: 1, scaleX: -1}}
                            whileInView={{x: 0, opacity: 1}}
                            transition={{duration: 0.8, ease: "linear"}}
                            viewport={{once: false}}
                        />
                    </div>
                    <div className={cl.right}>
                        <div className={cl.viewWindow}>
                            <div className={cl.reviews}>
                                <div
                                    className={cl.review}
                                    key={1}
                                    ref={reviewRefs[1]}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={clock} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Сайт со скоростью света — на старт, внимание, марш!</h3>
                                        <p>Создание сайтов под ключ настолько быстрое, что их запуск занимает меньше
                                            времени, чем ваше утреннее кофе. Делаем быстро и качественно!</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={1}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 0.6 ? `translateY(${-screenHeight * 0.6}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={clock} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Сайт со скоростью света — на старт, внимание, марш!</h3>
                                        <p>Создание сайтов под ключ настолько быстрое, что их запуск занимает меньше
                                            времени, чем ваше утреннее кофе. Делаем быстро и качественно!</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={2}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 1.2 ? `translateY(${-screenHeight * 1.2}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={rockets} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Космические технологии и приземленные цены</h3>
                                        <p>Мы занимаемся разработкой сайтов под ключ, как NASA запускает ракеты, но у нас это недорого и быстро. Пока другие веб-студии считают звёзды на своих ценниках, мы делаем круто и доступно.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={2}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 1.8 ? `translateY(${-screenHeight * 1.8}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={spy} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Технические Джеймсы Бонды</h3>
                                        <p>Как бы сложна ни была миссия: от создания Telegram ботов до мастерства в интерфейсах гигантских приложений — нашим спецагентам всё по плечу. Их миссия — качественная разработка веб-продуктов под ключ.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={2}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 2.4 ? `translateY(${-screenHeight * 2.4}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={chef} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Ваши идеи — наш основной ингредиент в супе решений</h3>
                                        <p>Мы готовим ваш веб-проект под ключ как шеф-повар в хорошем ресторане: уникально, с душой и по вашим рецептам. Готовим все от чизбургеров до конфи из утиных грудок с эмульсией из персикового редукта и перцевого фона. От несложных лендингов до крупнейших интернет-магазинов</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={2}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 3 ? `translateY(${-screenHeight * 3}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={prize} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Опыт, который нельзя купить на AliExpress</h3>
                                        <p>У нас работают ветераны разработки скриптов и создания сайтов! Они быстро и качественно превратят ваши идеи в цифровую реальность под ключ, и никто даже не заметит, как.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={2}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 3.6 ? `translateY(${-screenHeight * 3.6}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={headphones} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Дружеская техническая поддержка 25/8 (нет, это не опечатка)</h3>
                                        <p>Мы всегда на связи и готовы помочь вам с любыми вопросами по созданию сайтов под ключ и их эксплуатации. С нами легко и приятно работать! + кнопка связаться с менеджером.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                    key={2}
                                    ref={reviewRefs[1]}
                                    style={{
                                        transform: scrollOffset < -screenHeight * 4.2 ? `translateY(${-screenHeight * 4.2}px)` : `translateY(${scrollOffset}px)`, // Отрицательное значение для translateY вниз
                                        transition: 'transform 0.2s',
                                    }}
                                >
                                    <div className={cl.leftReview}>
                                        <img src={note} alt=""/>
                                    </div>
                                    <div className={cl.rightReview}>
                                        <h3>Клиенты говорят, мы слушаем и исполняем!</h3>
                                        <p>Ваше мнение — это мелодия, под которую мы танцуем. Расскажите, что нужно улучшить, — и мы отправимся на следующий этап SEO-оптимизации и разработки вашего сайта под ключ, чтобы сделать вам по настоящему высокую конверсию.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <canvas ref={canvasRef} style={{width: '100vw', height: '100vh', zIndex: -4, marginTop: '-104vh'}}/>
            </div>
        </div>
    );
};

export default ScrollOverlay;
