import React from 'react';
import cl from './MyCart.module.css'
const MyCart = (props) => {
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={cl.container}>
            <div>
                <div className={cl.photo}  onContextMenu={handleContextMenu} draggable={"false"}>
                    {props.image}
                </div>
                <div className={cl.name}> {props.name} </div>
            </div>
            <div className={cl.description}> {props.description} </div>
        </div>
    );
};

export default MyCart;