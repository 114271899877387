import React from 'react';
import cl from './ServicesIntro.module.css'
import services from '../../../images/shest.png'
const ServicesIntro = () => {
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={cl.container}>
            <div className={cl.left}>
                <h1 className={cl.name}>
                    <span>Р</span><span>А</span><span>З</span><span>Д</span><span>Е</span><span>Л</span>
                    <br/>
                    <span>У</span><span>С</span><span>Л</span><span>У</span><span>Г</span><span>И</span>
                </h1>
            </div>
            <div className={cl.right}>
                <img src={services} alt="механизм создание лендингов и интернет магазниов на заказ в Омске" onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
        </div>
    );
};

export default ServicesIntro;