import React, { useRef, useEffect } from 'react';
import honey from '../../images/hooooney.png';
import cl from './BeeWorld.module.css';
import BeeBack from "./BeeBack";

const BeeWorld = () => {
    const honeyRefs = useRef([]);

    const handleMouseMove = (e) => {
        const offsetX = (window.innerWidth / 2 - e.clientX) / 15;
        const offsetY = (window.innerHeight / 2 - e.clientY) / 15;

        honeyRefs.current.forEach(ref => {
            if (ref) {
                ref.style.transform = `translateX(${offsetX}px) translateY(${offsetY}px)`;
            }
        });
    };

    return (
        <div onMouseMove={handleMouseMove} style={{
            position: 'relative',
        }}>
             <div style={{
                        position: "absolute",
                        top: 0,
                        height: "15vh",
                        width: '100vw',
                        background: '#471c15',
                        zIndex: '1',
                        display: 'grid',
                        justifyContent: 'center'
                    }}>
                        <button className={cl.buttonLega}><a href="/main"  style={{
                            color:'inherit',
                            width: '35vw',
                            height: '10vh',
                            borderRadius: '10vh',
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center',
                            }}>Нажми чтобы продолжить</a></button>
                    </div>
            <BeeBack/>
            <div className={cl.titles}>
                <h1 className={cl.title}>
                    Трудолюбие <br /> и сладкий успех
                </h1>
                <h2 className={cl.subTitle}>
                    Работаем как пчёлки ради вас, <br /> создавая произведения искусства, а не сайты
                </h2>
            </div>
            <div className={cl.honeyContainer}>
                {['honey1', 'honey2', 'honey3', 'honey4'].map((honeyClass, idx) => (
                    <img
                        key={idx}
                        src={honey}
                        alt=""
                        className={cl[honeyClass]}
                        ref={el => honeyRefs.current[idx] = el}
                    />
                ))}
            </div>
        </div>
    );
};

export default BeeWorld;
