import React from 'react';
import cl from './MyName.module.css'
const MyName = (props) => {
    return (
        <h3 className={cl.container}>
           <div>
               {props.name}
           </div>
        </h3>
    );
};

export default MyName;