import React, {useLayoutEffect} from 'react';
import './MyService.css'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyService = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl.from(".alo", {
            y: 500,
        }).to(".alo",
            {
                y:0,
            }
        );

        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo1",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl1.from(".alo1", {
            y: 500,
        }).to(".alo1",
            {
                y:0,
            }
        );

        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo2",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl2.from(".alo2", {
            y: 500,
        }).to(".alo2",
            {
                y:0,
            }
        );

        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo3",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl3.from(".alo3", {
            y: 500,
        }).to(".alo3",
            {
                y:0,
            }
        );

        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo4",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl4.from(".alo4", {
            y: 500,
        }).to(".alo4",
            {
                y:0,
            }
        );

        const tl5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo5",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl5.from(".alo5", {
            y: 500,
        }).to(".alo5",
            {
                y:0,
            }
        );

        const tl6 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo6",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl6.from(".alo6", {
            y: 500,
        }).to(".alo6",
            {
                y:0,
            }
        );

        const tl7 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo7",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl7.from(".alo7", {
            y: 500,
        }).to(".alo7",
            {
                y:0,
            }
        );

        const tl8 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo8",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl8.from(".alo8", {
            y: 500,
        }).to(".alo8",
            {
                y:0,
            }
        );

        const tl9 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo9",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl9.from(".alo9", {
            y: 500,
        }).to(".alo9",
            {
                y:0,
            }
        );

        const tl10 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo10",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl10.from(".alo10", {
            y: 500,
        }).to(".alo10",
            {
                y:0,
            }
        );

        const tl11 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo11",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl11.from(".alo11", {
            y: 500,
        }).to(".alo11",
            {
                y:0,
            }
        );

        const tl12 = gsap.timeline({
            scrollTrigger: {
                trigger: ".alo12",
                scrub: true,
                start: "-60% bottom",
                end: "-50% 50%",
            }
        })

        tl12.from(".alo12", {
            y: 500,
        }).to(".alo12",
            {
                y:0,
            }
        );
    }, []);
    return (
        <div className={"mainContainer"}>
            <div className={"serSector"}>
                <h2 className={"nameSector"}>Сайты</h2>
                <div className="serviceContainer">
                    <div className={"serviceBlock alo"}>
                        <h3 className={"serviceName"}>Лендинг</h3>
                        <p className={"serviceDescription"}>Лендинг — это одностраничный сайт, предназначенный для продвижения конкретного продукта или услуги. Лендинг идеально подходит для стартапов и небольших компаний.</p>
                        <div className={"serviceCost"}>от 25.000₽</div>
                    </div>
                    <div className={"serviceBlock alo1"}>
                        <h3 className={"serviceName"}>Многостр. сайт</h3>
                        <p className={"serviceDescription"}>Многостраничный сайт — это сайт с несколькими страницами, каждая из которых имеет свою уникальную цель и функцию. Подходит для уже более крупных компаний.</p>
                        <div className={"serviceCost"}>от 45.000₽</div>
                    </div>
                    <div className={"serviceBlock alo2"}>
                        <h3 className={"serviceName"}>Интернет-магазин</h3>
                        <p className={"serviceDescription"}>Интернет-магазин — это веб-сайт, предназначенный для продажи товаров и услуг онлайн с функциями корзины покупок, каталога и платежных систем. Рекомендуем Вам купить интернет-магазин под ключ.</p>
                        <div className={"serviceCost"}>от 60.000₽</div>
                    </div>
                    <div className={"serviceBlock alo3"}>
                        <h3 className={"serviceName"}>Верстка по дизайну</h3>
                        <p className={"serviceDescription"}>Создание сайта, следуя вашему дизайн-проекту при помощи HTML, CSS и JavaScript.</p>
                        <div className={"serviceCost"}>от 20.000₽</div>
                    </div>
                    <div className={"serviceBlock alo4"}>
                        <h3 className={"serviceName"}>Дизайн</h3>
                        <p className={"serviceDescription"}>Создание веб-дизайна — это процесс создания визуальных элементов для веб-сайтов, логотипов и других медиа, направленных на улучшение эстетики и функциональности вашего проекта.</p>
                        <div className={"serviceCost"}>от 10.000₽</div>
                    </div>
                </div>
            </div>
            <div className={"serSector"}>
                <h2 className={"nameSector"}>Продвижение</h2>
                <div className="serviceContainer">
                    <div className={"serviceBlock alo5"}>
                        <h3 className={"serviceName"}>SEO</h3>
                        <p className={"serviceDescription"}>SEO продвижение — это процесс улучшения видимости сайта в поисковых системах с помощью оптимизации контента и структуры сайта.</p>
                        <div className={"serviceCost"}>25.000₽</div>
                    </div>
                    <div className={"serviceBlock alo6"}>
                        <h3 className={"serviceName"}>SMM</h3>
                        <p className={"serviceDescription"}>SMM продвижение – это процесс продвижения бизнеса и взаимодействия с аудиторией через социальные сети.</p>
                        <div className={"serviceCost"}>30.000₽</div>
                    </div>
                    <div className={"serviceBlock alo7"}>
                        <h3 className={"serviceName"}>Контекстная реклама</h3>
                        <p className={"serviceDescription"}>Контекстная реклама — это реклама в поисковых системах и на партнерских сайтах, отображаемая на основе интересов и запросов пользователей. С нами вы можете подключить Яндекс.Директ.</p>
                        <div className={"serviceCost"}>25.000₽</div>
                    </div>
                    <div className={"serviceBlock alo8"}>
                        <h3 className={"serviceName"}>Прочие</h3>
                        <p className={"serviceDescription"}>Рассылки на почту и другие менее эффективные виды рекламы в интернете</p>
                        <div className={"serviceCost"}></div>
                    </div>
                </div>
            </div>
            <div className={"serSector"}>
                <h2 className={"nameSector"}>Сопровождение</h2>
                <div className="serviceContainer">
                    <div className={"serviceBlock alo9"}>
                        <h3 className={"serviceName"}>Управление контентом</h3>
                        <p className={"serviceDescription"}>Процесс создания, обновления и оптимизации контента на сайте для поддержания его актуальности и повышения интереса пользователей.</p>
                        <div className={"serviceCost"}>25.000₽</div>
                    </div>
                    <div className={"serviceBlock alo10"}>
                        <h3 className={"serviceName"}>Тех. поддержка</h3>
                        <p className={"serviceDescription"}>Комплекс услуг по поддержке и обслуживанию сайта, включая устранение ошибок, обновления и обеспечение безопасности</p>
                        <div className={"serviceCost"}>10.000₽</div>
                    </div>
                    <div className={"serviceBlock alo11"}>
                        <h3 className={"serviceName"}>Мониторинг и аналитика</h3>
                        <p className={"serviceDescription"}>Процесс отслеживания и анализа показателей работы сайта для принятия обоснованных решений по его улучшению.</p>
                        <div className={"serviceCost"}>от 15.000₽</div>
                    </div>
                </div>

            </div>
            <div className={"keySector"}>
                <h2 className={"nameKeer"}>"Под ключ"</h2>
                <div className={"keyContainer"}>
                <div className={"key"}>
                    <div className={"serviceName"} style={{
                        color: "#fc7c33"
                    }}>Start</div>
                    <div className={"serviceDescription"}>
                        <h2 className={"name"}>
                            Создание сайта:
                        </h2>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>
                                Индивидуальный дизайн
                            </h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Адаптивность</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Наполнение контентом</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Анимации на сайте</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Кроссбраузерность</h3>
                        </div>
                        <h2 className={"name"}>
                            Продвижение:
                        </h2>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>SEO-оптимизация (lvl.Start)</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Яндекс.Аналитика</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Скидка 10% на пакеты сопровождения</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Хостинг и доменное имя</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Яндекс.Директ</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Техническая поддержка</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>SMM-продвижение</h3>
                        </div>
                    </div>
                    <div className={"serviceCost2"}>от 60.000₽</div>
                </div>
                <div className={"key"}>
                    <div className={"serviceName"} style={{
                        color: "#fc7c33"
                    }}>Progress</div>
                    <div className={"serviceDescription"}>
                        <h2 className={"name"}>
                            Создание сайта:
                        </h2>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>
                                Индивидуальный дизайн
                            </h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Адаптивность</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Наполнение контентом</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Анимации на сайте</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Кроссбраузерность</h3>
                        </div>
                        <h2 className={"name"}>
                            Продвижение:
                        </h2>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>SEO-оптимизация (lvl.Progress)</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Яндекс.Аналитика</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Скидка 15% на пакеты сопровождения</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Хостинг и доменное имя</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Яндекс.Директ</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>Техническая поддержка</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✖</div>
                            <h3>SMM-продвижение</h3>
                        </div>
                    </div>
                    <div className={"serviceCost2"}>от 100.000₽</div>
                </div>
                <div className={"key"}>
                    <div className={"serviceName"} style={{
                        color: "#fc7c33"
                    }}>VIP</div>
                    <div className={"serviceDescription"}>
                        <h2 className={"name"}>
                            Создание сайта:
                        </h2>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>
                                Индивидуальный дизайн
                            </h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Адаптивность</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Наполнение контентом</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Анимации на сайте</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Кроссбраузерность</h3>
                        </div>
                        <h2 className={"name"}>
                            Продвижение:
                        </h2>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>SEO-оптимизация (lvl.VIP)</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Яндекс.Аналитика</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Скидка 25% на пакеты сопровождения</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Хостинг и доменное имя</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Яндекс.Директ</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>Техническая поддержка</h3>
                        </div>
                        <div className={"cont"}>
                            <div className={"indicator"}>✔</div>
                            <h3>SMM-продвижение</h3>
                        </div>
                    </div>
                    <div className={"serviceCost2"}>от 140.000₽</div>
                </div>
                </div>
            </div>
            <div className={"serSector"}>
                <h2 className={"nameSector"}>Прочие услуги</h2>
                <div className="serviceContainer">
                    <div className={"serviceBlock alo12"}>
                        <h3 className={"serviceName"}>Создание tg-ботов</h3>
                        <p className={"serviceDescription"}>Разработка автоматизированных Telegram-ботов для выполнения рутинных задач и улучшения взаимодействия с клиентами.</p>
                        <div className={"serviceCost"}>от 15.000₽</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyService;