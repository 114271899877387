import React, {useLayoutEffect} from 'react';
import cl from './AboutMission.module.css'
import astro from '../../../images/astro.png'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const AboutMission = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".image",
                scrub: true,
                start: "0% bottom",
                end: "20% 50%",
            }
        })

        tl.from(".image", {
            y: 500,
        }).to(".image",
            {
                y:0,
            }
        );
        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".right",
                scrub: true,
                start: "-55% bottom",
                end: "-21% 50%",
            }
        })

        au.from(".right", {
            x: 900,
        }).to(".right",
            {
                x:0,
            }
        );


    }, []);
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={cl.container}>
            <div className={cl.left}>
                <img src={astro} alt="астронавт ищет создание сайтов от gotem в Омске" className={"image"} onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
            <div className={cl.rightCont}>
                <div  className={"right"}>
                    <h2 className={cl.name}>
                        Миссия GoTem_web
                    </h2>
                    <p className={cl.description}>
                        Наша миссия – делать интернет-пространство удобным и функциональным, предоставляя решения под ключ в области веб-дизайна и разработки сайтов.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutMission;