import React, {useEffect, useRef, useState} from 'react';
import cl from "../pages/Main.module.css";
import clock from "../images/clock.png";
import seno from "../images/seno.png";
import durov from "../images/durov.png";
import oridopota from "../images/oridopota.png";
import torf from "../images/torf.png";
import noname from "../images/noname.png";
import dibil from "../images/dibil.png";

const HorizontalScroll = () => {
    const containerRef = useRef(null);
    const [horizontalScrollAllowed, setHorizontalScrollAllowed] = useState(false);
    const [virgin1,setVirgin1] = useState(true);
    const [virgin2,setVirgin2] = useState(true);
    const [scrollAllow, setScrollAllow] = useState(false);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleScroll = (e) => {
            const container = containerRef.current;
            if (container) {
                const rect = container.getBoundingClientRect();
                const shouldEnableHorizontalScroll = rect.top <= 0;
                const shouldEnableHorizontalScroll2 = rect.bottom >= screenHeight;

                if (shouldEnableHorizontalScroll && virgin1 && !shouldEnableHorizontalScroll2) {
                    setScrollAllow(true)
                    document.body.style.overflowY = shouldEnableHorizontalScroll ? 'hidden' : 'auto';
                    e.deltaY = 0
                }
                if (shouldEnableHorizontalScroll2 && !virgin2 && !shouldEnableHorizontalScroll) {
                    setScrollAllow(true)
                    document.body.style.overflowY = shouldEnableHorizontalScroll2 ? 'hidden' : 'auto';
                    e.deltaY = 0
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.body.style.overflowY = 'auto';
        };
    }, [scrollAllow]);

    const handleHorizontalScroll = (e) => {
        if (!scrollAllow) return;

        const container = containerRef.current;
        if (scrollAllow) {
            const scrollThreshold = 25; // Порог в 50 пикселей от конца
            const atStart = container.scrollLeft === 0;
            const nearEnd = container.scrollLeft + container.offsetWidth >= container.scrollWidth - scrollThreshold;

            if (atStart && e.deltaY <= 0) {
                document.body.style.overflowY = 'auto';
                setVirgin1(true)
                setVirgin2(true)
                setScrollAllow(false)
            } else if (nearEnd && e.deltaY >= 0) {
                document.body.style.overflowY = 'auto';
                setVirgin1(false)
                setVirgin2(false)
                setScrollAllow(false)
            } else {
                container.scrollLeft += e.deltaY;
                e.preventDefault();
                setScrollAllow(true)
                document.body.style.overflowY = 'hidden';
            }
        }
    };

    return (
        <div className={cl.feedBack} ref={containerRef} onWheel={handleHorizontalScroll}
             style={{
                 overflowX: scrollAllow ? 'auto' : 'hidden',
                 position: scrollAllow ? 'sticky' : 'relative',
                 top: scrollAllow ? 0 : 'auto',
                 zIndex: 2

        }}>
            <div className={cl.feed}>
                <div className={cl.feedName}>
                    <div className={cl.leftName}>
                        <img src={durov} alt=""/>
                    </div>
                    <div className={cl.rightName}>
                        <h3>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Имя:</div>
                            Паша Дуров
                        </h3>
                        <h4>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Компания:
                            </div>
                            Телегрэмм
                        </h4>
                    </div>
                </div>
                <div className={cl.feedDescr}>
                    Ребята сделали настолько классный мессенджер, что мои друзья из Франции решили украсть у меня
                    его(((( <br/> За это только 4 звезды
                    <div className={cl.feedStars}>
                        <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                             xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path className="cls-1"
                                      d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                      id="rating" transform="translate(-629.938 -210)"></path>
                            </g>
                        </svg>
                        <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                             xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path className="cls-1"
                                      d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                      id="rating" transform="translate(-629.938 -210)"></path>
                            </g>
                        </svg>
                        <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                             xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path className="cls-1"
                                      d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                      id="rating" transform="translate(-629.938 -210)"></path>
                            </g>
                        </svg>
                        <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                             xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path className="cls-1"
                                      d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                      id="rating" transform="translate(-629.938 -210)"></path>
                            </g>
                        </svg>
                    </div>

                </div>
            </div>
            <div className={cl.feed}>
                <div className={cl.feedName}>
                    <div className={cl.leftName}>
                        <img src={oridopota} alt=""/>
                    </div>
                    <div className={cl.rightName}>
                        <h3>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Имя:</div>
                            Оридо Пота
                        </h3>
                        <h4>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Компания:
                            </div>
                            КАЧАЛКА
                        </h4>
                    </div>
                </div>
                <div className={cl.feedBody}>

                    <div className={cl.feedDescr}>
                        Cltkfkb htfkmyj rhenjq cfqn!!!!!!!! Ntgthm e vtyz htfkmyj jtym vyjuj rkbtynjd b vs dvtcnt
                        rfxftvcz!!!!!!!))))))))))
                        <div className={cl.feedStars}>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
            <div className={cl.feed}>
                <div className={cl.feedName}>
                    <div className={cl.leftName}>
                        <img src={seno} alt=""/>
                    </div>
                    <div className={cl.rightName}>
                        <h3>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Имя:</div>
                            Nakosika Sena
                        </h3>
                        <h4>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Компания:
                            </div>
                            Жапан Фармс
                        </h4>
                    </div>
                </div>
                <div className={cl.feedBody}>

                    <div className={cl.feedDescr}>
                        РЕБЯТА ЭТО ЛУЧШАЯ КОМПАНИЯ ПО РАЗРАБОТКЕ САЙТОВ И ВСЕГО ТАКОГО. С НИМИ Я ЗАХВАТИЛ РЫНОК
                        СЕНА ВО ВСЕЙ ЯПОНИИ. ИСПОЛЬЗУЙТЕ ПРОМОКОД СЕНО5 ЧТОБЫ ПОЛУЧИТЬ СКИДКУ 5%
                        <div className={cl.feedStars}>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
            <div className={cl.feed}>
                <div className={cl.feedName}>
                    <div className={cl.leftName}>
                        <img src={torf} alt=""/>
                    </div>
                    <div className={cl.rightName}>
                        <h3>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Имя:</div>
                            Билл Воротник
                        </h3>
                        <h4>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Компания:
                            </div>
                            Микроторф
                        </h4>
                    </div>
                </div>
                <div className={cl.feedBody}>

                    <div className={cl.feedDescr}>
                        Мне создали отличный интернет-магазин по продаже торфа. Теперь торф — самый популярный продукт
                        на мировом рынке
                        <div className={cl.feedStars}>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
            <div className={cl.feed}>
                <div className={cl.feedName}>
                    <div className={cl.leftName}>
                        <img src={dibil} alt=""/>
                    </div>
                    <div className={cl.rightName}>
                        <h3>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Имя:</div>
                            Анатолий Дибильный
                        </h3>
                        <h4>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Компания:
                            </div>
                            Фиктивные Проекты
                        </h4>
                    </div>
                </div>
                <div className={cl.feedBody}>

                    <div className={cl.feedDescr}>
                        Да по-моему фигня какая-то
                        <div className={cl.feedStars}>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
            <div className={cl.feed}>
                <div className={cl.feedName}>
                    <div className={cl.leftName}>
                        <img src={noname} alt=""/>
                    </div>
                    <div className={cl.rightName}>
                        <h3>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Имя:</div>
                            Геннадий Шифр
                        </h3>
                        <h4>
                            <div style={{color: '#fc7c33', display: 'inline-block', paddingRight: '1vw'}}>Компания:
                            </div>
                            Тайны CO.
                        </h4>
                    </div>
                </div>
                <div className={cl.feedBody}>
                    <div className={cl.feedDescr}>
                        *Пользователь не оставил комментариев*
                        <div className={cl.feedStars}>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                            <svg width='20px' height='20px' viewBox="0 -0.03 60.062 60.062"
                                 xmlns="http://www.w3.org/2000/svg" fill="#fc7c33" stroke="#fc7c33">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path className="cls-1"
                                          d="M670.68,227.733a3.03,3.03,0,0,0,.884,1.072,3.168,3.168,0,0,0,1.282.578l14.662,2.965a3.067,3.067,0,0,1,2.394,2.284,3,3,0,0,1-1.118,3.084l-11.408,8.654a3.01,3.01,0,0,0-.994,1.3,2.956,2.956,0,0,0-.16,1.618L679.3,266.42a3,3,0,0,1-1.275,3.01,3.166,3.166,0,0,1-3.328.146l-13.18-7.407a3.165,3.165,0,0,0-3.091,0l-13.181,7.407a3.156,3.156,0,0,1-3.327-.146,3,3,0,0,1-1.275-3.01l3.078-17.129a2.956,2.956,0,0,0-.16-1.618,3.01,3.01,0,0,0-.994-1.3l-11.408-8.654a3,3,0,0,1-1.118-3.084,3.068,3.068,0,0,1,2.393-2.284l14.66-2.965a3.141,3.141,0,0,0,1.281-.578,3.044,3.044,0,0,0,.885-1.072l7.918-16.013a3.133,3.133,0,0,1,5.587,0Z"
                                          id="rating" transform="translate(-629.938 -210)"></path>
                                </g>
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalScroll;