import React, {useEffect, useRef} from 'react';
import cl from './MyProcess.module.css'

const MyProcess = () => {

    const horizontalSectionRef = useRef(null);

    useEffect(() => {
        const horizontalContainer = horizontalSectionRef.current;
        let disableScroll = false;

        const handleScroll = (event) => {
            if (disableScroll) return;

            if (horizontalContainer) {
                if (event.deltaY > 0) { // Скролл вниз
                    if (horizontalContainer.scrollWidth > horizontalContainer.clientWidth) {
                        horizontalContainer.scrollLeft += event.deltaY;
                        event.preventDefault();
                    }
                    // Проверим, достиг ли скроллинг конца контейнера
                    const maxScrollLeft = horizontalContainer.scrollWidth - horizontalContainer.clientWidth;
                    if (horizontalContainer.scrollLeft >= maxScrollLeft - 25) {
                        window.removeEventListener('wheel', handleScroll);
                    }
                } else { // Скролл вверх
                    if (horizontalContainer.scrollLeft > 0) {
                        horizontalContainer.scrollLeft += event.deltaY;
                        event.preventDefault();
                    } else {
                        window.removeEventListener('wheel', handleScroll);
                    }
                }
            }
        };

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (entry.boundingClientRect.top > 0) {
                            // Начало блока вошло в экран
                            window.addEventListener('wheel', handleScroll, { passive: false });
                        } else if (entry.boundingClientRect.bottom <= window.innerHeight) {
                            // Нижняя часть блока совпала с нижней частью viewport
                            window.addEventListener('wheel', handleScroll, { passive: false });
                        }
                    } else {
                        window.removeEventListener('wheel', handleScroll);
                    }
                });
            },
            { threshold: 1.0 }
        );

        if (horizontalContainer) {
            const startObserver = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        disableScroll = false;
                    } else {
                        disableScroll = true;
                    }
                },
                { threshold: 0 }
            );

            observer.observe(horizontalContainer);
            startObserver.observe(horizontalContainer);

            return () => {
                observer.unobserve(horizontalContainer);
                startObserver.unobserve(horizontalContainer);
                window.removeEventListener('wheel', handleScroll);
            };
        }
    }, []);

    return (
        <div className={cl.glContainer}>
            <h2 className={cl.big}>
                Процесс Работы
            </h2>
            <div ref={horizontalSectionRef} className={cl.container}>
                            <div className={"block"}
                                 style={{
                                     transition: "all, 0.5s"
                                 }}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        Выбор проекта
                                    </h2>
                                    <div className={cl.number}>
                                        01
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Бесплатная консультация, поможем вам выбрать подходящий проект.
                                </p>
                            </div>
                            <div className={"block1"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        Бумажные моменты
                                    </h2>
                                    <div className={cl.number}>
                                        02
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Мы определяем цену, время создания сайта. Согласуем SEO продвижение и контекстную
                                    рекламу. Получаем первую часть денег.
                                </p>
                            </div>
                            <div className={"block2"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        Дизайн
                                    </h2>
                                    <div className={cl.number}>
                                        03
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Мы работаем над дизайном сайта, а также продумываем анимации. Согласуем
                                    дизайн-проект с
                                    вами.
                                </p>
                            </div>
                            <div className={"block3"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        FrontEnd
                                    </h2>
                                    <div className={cl.number}>
                                        04
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Разрабатываем внешнюю часть сайта. Реализуем дизайн.
                                </p>
                            </div>
                            <div className={"block4"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        BackEnd
                                    </h2>
                                    <div className={cl.number}>
                                        05
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Прорабатываем внутренние механизмы сайта. Реализуем его работоспособность.
                                </p>
                            </div>
                            <div className={"block5"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        Финальные шаги
                                    </h2>
                                    <div className={cl.number}>
                                        06
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Показываем вам сайт, реализуем ваши последние желания. Получаем вторую часть денег.
                                </p>
                            </div>
                            <div className={"block6"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        Хостинг
                                    </h2>
                                    <div className={cl.number}>
                                        07
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Выбираем нужный вам тариф, размещаем сайт в сети.
                                </p>
                            </div>
                            <div className={"block7"}>
                                <div className={cl.naming}>
                                    <h2 className={cl.name}>
                                        Сотрудничество
                                    </h2>
                                    <div className={cl.number}>
                                        08
                                    </div>
                                </div>
                                <p className={cl.description}>
                                    Поддерживаем и дорабатываем ваш сайт. Продвигаем его в сети. Дополняем его новыми
                                    функциями.
                                </p>
                            </div>
                        </div>
        </div>

    );
};

export default MyProcess;