import React from 'react';
import cl from './Begin.module.css'
import help from '../../../images/helpin.png'
const Begin = () => {
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={cl.container}>
            <div className={cl.left}>
                <h1 className={cl.name}>
                    <span>Р</span><span>А</span><span>З</span><span>Д</span><span>Е</span><span>Л</span>
                    <br/>
                    <span>П</span><span>О</span><span>М</span><span>О</span><span>Щ</span><span>Ь</span>
                </h1>
            </div>
            <div className={cl.right} onContextMenu={handleContextMenu} draggable={"false"}>
                <img src={help} alt="разработчик думает как заказать сайт в омске"/>
            </div>
        </div>
    );
};

export default Begin;