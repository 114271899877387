import React, {useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {IMaskInput} from "react-imask";
import './MyFooter.css'
const MyFooter = () => {

    const [name1,setName1] = useState('')
    const [number1,setNumber1] = useState('')
    const [telegram1, setTelegram1] = useState('')
    const [description1,setDescription1] = useState('')
    const [agree, setAgree] = useState(false)

let lastRun = 0;
    const porn = () => {
        const now = Date.now();
        const oneMinute = 60000;
        if (now - lastRun >= oneMinute){
            if((telegram1.length < 5) && (number1.length < 17)){
                toast("Одно из полей Телеграм или Телефон должно быть заполнено", {
                    autoClose: 5000,
                    theme: "dark",
                    className: "toastMessage",
                    position: "top-right",
                });
            }
            else {
                if(agree){
                    sendMessageToTelegram(
                        [
                            'Имя: ' + name1 + '\n' + 'Телегрэм: @' + telegram1 + '\n' + 'Телефоне: ' + number1 + '\n' + 'Тз: ' + description1 + '\n'
                        ]
                    );
                    toast("Заявка отправлена", {
                        autoClose: 5000,
                        theme: "dark",
                        className: "toastMessage",
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    if (window.ym) {
                        window.ym(98043044, 'reachGoal', 'FORM_SUBMIT');
                    }
                    lastRun = now;
                }
                else{
                    toast("Дайте согласие на обработку данных", {
                        autoClose: 5000,
                        theme: "dark",
                        className: "toastMessage",
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        }
        else {
            toast(`${Math.round(60 - (now - lastRun)/1000)} секунд до возможности отправить еще одну заявку`,{
                autoClose: 5000,
                theme: "dark",
                className: "toastMessage",
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    }
    const token = '6816763130:AAFV6HUDz4X8IfYcLTGAX7_UWuycOThGQHA';
    const chatId1 = 5295473053;
    const chatId2 = 1808077234;

    const sendMessageToTelegram = (message) => {
        fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId1}&text=${encodeURIComponent(message)}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error(error);
            });
        fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId2}&text=${encodeURIComponent(message)}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error(error);
            });
    }


    return (
        <div className="footer">
            <div className="top">
                <h2 className="namer">
                    Оставить заявку
                </h2>
                <div className="container">
                    <div className="left">
                        <div>
                            <div className="naming">Имя</div>
                            <div>
                                <input type="text" minLength={3} maxLength={50} placeholder={'Ваше имя'} value={name1} onChange={(e) => setName1(e.target.value)}/>
                            </div>
                        </div>
                        <div>
                            <div className="naming">Телеграм</div>
                            <div>
                                <IMaskInput
                                    mask=")##############################!"
                                    definitions={{
                                        '#': /[1-9,a-z, A-Z, _]/,
                                        '!': /[1-9, a-z, A-Z]/,
                                        ')': /[a-z, A-Z]/,
                                    }}
                                    minLength={5}
                                    overwrite
                                    placeholder={'Телеграм @username'}
                                    value={telegram1}
                                    onChange={(e) => setTelegram1(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="naming">Телефон</div>
                            <div>
                                <IMaskInput
                                    mask="+7(#00) 000-00-00"
                                    definitions={{
                                        '#': /[1-9]/,
                                    }}
                                    minLength={17}
                                    overwrite
                                    type="tel"
                                    placeholder={'+7 (999) 999 99-99'}
                                    value={number1}
                                    onChange={(e) => setNumber1(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="right">
                        <div className="naming">Кратко опишите вашу задачу</div>
                        <div>
                            <textarea name="" id=""  placeholder={'Ваша задача...'} value={description1} onChange={(e) => setDescription1(e.target.value)}>

                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="buttonBlock">
                    <label className="customCheckbox" htmlFor={'agree1'}>
                        <input type="checkbox" id={'agree1'} name={'agree'} onChange={() => setAgree(!agree)}/>
                        <span>Согласие на обработку данных</span>
                    </label>
                    <button onClick={porn}>Отправить заявку</button>
                </div>
            </div>
            <div className="bottom">
                <div className="botContainer">
                    <a className="connect" href={"https://t.me/gotem_web"} target={"_blank"}>
                        <div><svg fill="#212121" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>telegram</title> <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"></path> </g></svg></div>
                        <a className="net" href={"https://t.me/gotem_web"} target={"_blank"}>tg</a>
                    </a>
                    <a className="connect" href={'https://vk.com/gotemweb'} target={"_blank"}>
                        <div>
                            <svg fill="#212121" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zm3.692 10.831s.849.838 1.058 1.227c.006.008.009.016.011.02.085.143.105.254.063.337-.07.138-.31.206-.392.212h-1.5c-.104 0-.322-.027-.586-.209-.203-.142-.403-.375-.598-.602-.291-.338-.543-.63-.797-.63a.305.305 0 0 0-.095.015c-.192.062-.438.336-.438 1.066 0 .228-.18.359-.307.359h-.687c-.234 0-1.453-.082-2.533-1.221-1.322-1.395-2.512-4.193-2.522-4.219-.075-.181.08-.278.249-.278h1.515c.202 0 .268.123.314.232.054.127.252.632.577 1.2.527.926.85 1.302 1.109 1.302a.3.3 0 0 0 .139-.036c.338-.188.275-1.393.26-1.643 0-.047-.001-.539-.174-.775-.124-.171-.335-.236-.463-.26a.55.55 0 0 1 .199-.169c.232-.116.65-.133 1.065-.133h.231c.45.006.566.035.729.076.33.079.337.292.308 1.021-.009.207-.018.441-.018.717 0 .06-.003.124-.003.192-.01.371-.022.792.24.965a.216.216 0 0 0 .114.033c.091 0 .365 0 1.107-1.273a9.718 9.718 0 0 0 .595-1.274c.015-.026.059-.106.111-.137a.266.266 0 0 1 .124-.029h1.781c.194 0 .327.029.352.104.044.119-.008.482-.821 1.583l-.363.479c-.737.966-.737 1.015.046 1.748z"></path></g></svg>
                        </div>
                        <a className="net" href={'https://vk.com/gotemweb'} target={"_blank"}>vk</a>
                    </a>
                    <div className="connect">
                        <div className="net">gotem_web@mail.ru</div>
                    </div>
                    <div className="mail">
                        <a href="/politic">Политика конфиденциальности</a>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MyFooter;