import React, { useRef, useEffect } from 'react';
import cl from './Heaven.module.css';
import cloud from '../../images/cloud.png';

const Heaven = () => {
    const skyRef = useRef(null);
    const textBlockRef = useRef(null);
    const cloudsRef = useRef([]);

    useEffect(() => {
        const handleMouseMove = (e) => {
            // Для создания следов облаков
            const cloudTrail = document.createElement('div');
            cloudTrail.className = cl.cloudTrail;
            cloudTrail.style.left = `${e.clientX}px`;
            cloudTrail.style.top = `${e.clientY}px`;
            document.body.appendChild(cloudTrail);

            setTimeout(() => {
                cloudTrail.remove();
            }, 5000); // След облаков исчезнет через 5 секунд

            // Для эффекта параллакса
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;
            const dx = (e.clientX - centerX) / 100;
            const dy = (e.clientY - centerY) / 100;

            requestAnimationFrame(() => {
                textBlockRef.current.style.transform = `translate(${dx * 2}px, ${dy * 2}px)`;
                cloudsRef.current.forEach((cloud, index) => {
                    const direction = index % 2 === 0 ? 1 : -1;
                    cloud.style.transform = `translate(${dx * (index + 1) * direction}px, ${dy * (index + 1) * direction}px)`;
                });
            });
        };

        const sky = skyRef.current;
        sky.addEventListener('mousemove', handleMouseMove);

        // Удаление обработчика событий при размонтировании
        return () => {
            sky.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className={cl.sky} ref={skyRef}>
             <div style={{
                        position: "absolute",
                        top: 0,
                        height: "15vh",
                        width: '100vw',
                        background: '#d4dbe5',
                        zIndex: '19999999999999999999999999999999',
                        cursor: 'default',
                        display: 'grid',
                        justifyContent: 'center'
                    }}>
                        <button className={cl.buttonLega}><a href="/main" style={{
                            color:'inherit',
                            width: '35vw',
                            height: '10vh',
                            borderRadius: '10vh',
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center',
                            }}>Нажми чтобы продолжить</a></button>
                    </div>
            <div className={cl.textBlock} ref={textBlockRef}>
                <h1 className={cl.title}>Наши сайты выше облаков</h1>
                <h2 className={cl.subtitle}>С Gotem_web ваши продажи улетают в небеса</h2>
            </div>
            <div className={cl.cloudContainer}>

                {['honey1', 'honey2', 'honey3', 'honey4'].map((className, index) => (
                    <img
                        src={cloud}
                        alt=""
                        className={cl[className]}
                        key={index}
                        ref={(el) => (cloudsRef.current[index] = el)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Heaven;