import React,{useLayoutEffect} from 'react';
import cl from './AboutTeam.module.css'
import team from '../../../images/group.png'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
const AboutMission = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".imagination",
                scrub: true,
                start: "-10% bottom",
                end: "20% 50%",
            }
        })

        tl.from(".imagination", {
            y: 500,
        }).to(".imagination",
            {
                y:0,
            }
        );
        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".left",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        au.from(".left", {
            x: -900,
        }).to(".left",
            {
                x:0,
            }
        );


    }, []);
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={cl.container}>
            <div className={cl.leftContainer}>
                <div className={"left"}>
                    <h2 className={cl.name}>
                        Команда GoTem_web
                    </h2>
                    <p className={cl.description}>
                        Наша команда состоит полностью из обученных специалистов, профессионалов своего дела. Они как никто другой помогут вам с вашей проблемой и реализуют любое ваше желание!
                    </p>
                    <a href={'./team'} className={cl.link}>ПОДРОБНЕЕ</a>
                </div>
            </div>
            <div className={cl.right}>
                <img src={team} alt="группа разработчиков лендингов и интернет магазинов под ключ в Омске" className={"imagination"} onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
        </div>
    );
};

export default AboutMission;