import React, {useLayoutEffect} from 'react';
import cl from './Quest.module.css'
import MyQuest from "./MyQuest";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Quest = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl.from(".frag", {
            x: 1500,
        }).to(".frag",
            {
                x:0,
            }
        );


        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag1",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl1.from(".frag1", {
            x: 1500,
        }).to(".frag1",
            {
                x:0,
            }
        );

        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag2",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl2.from(".frag2", {
            x: 1500,
        }).to(".frag2",
            {
                x:0,
            }
        );


        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag3",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl3.from(".frag3", {
            x: 1500,
        }).to(".frag3",
            {
                x:0,
            }
        );


        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag4",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl4.from(".frag4", {
            x: 1500,
        }).to(".frag4",
            {
                x:0,
            }
        );


        const tl5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag5",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl5.from(".frag5", {
            x: 1500,
        }).to(".frag5",
            {
                x:0,
            }
        );


        const tl6 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag6",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl6.from(".frag6", {
            x: 1500,
        }).to(".frag6",
            {
                x:0,
            }
        );


        const tl7 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag7",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl7.from(".frag7", {
            x: 1500,
        }).to(".frag7",
            {
                x:0,
            }
        );


        const tl8 = gsap.timeline({
            scrollTrigger: {
                trigger: ".frag8",
                scrub: true,
                start: "-30% bottom",
                end: "-15% 50%",
            }
        })

        tl8.from(".frag8", {
            x: 1500,
        }).to(".frag8",
            {
                x:0,
            }
        );
    }, []);
    return (
        <div className={cl.container}>
            <div className={'frag'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Что такое веб-студия GoTem_web?'} answer={'GoTem_web – это профессиональная веб-студия в Омске, специализирующаяся на создании уникальных сайтов, дизайне и продвижении. Мы помогаем бизнесам выделяться в интернете и привлекать клиентов.'}/>
            </div>
            <div className={'frag1'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Какие услуги предоставляет GoTem_web?'} answer={'Мы предлагаем полный спектр услуг: разработка веб-сайтов, создание уникальных дизайнов, SEO-продвижение, техподдержка и контент-менеджмент. Вам не нужно обращаться к нескольким провайдерам – мы всё сделаем за вас!'}/>
            </div>
            <div className={'frag2'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Какова стоимость создания сайта в GoTem_web?'} answer={'Стоимость создания сайта зависит от ваших требований и сложности проекта. Мы предлагаем индивидуальные решения под каждый запрос. Свяжитесь с нами для получения бесплатной консультации.'}/>
            </div>
            <div className={'frag3'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Как долго занимает разработка сайта?'} answer={'Время разработки сайта варьируется в зависимости от сложности проекта и ваших пожеланий, но обычно это занимает от 1 до 8 недель. Мы всегда стараемся работать быстро и эффективно, не снижая качества работы.'}/>
            </div>
            <div className={'frag4'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Что включено в SEO-продвижение от GoTem_web?'} answer={'SEO-продвижение включает в себя анализ ключевых слов, оптимизацию контента, внутреннюю и внешнюю оптимизацию, улучшение структуры сайта, анализ конкурентов и регулярные отчёты о результатах.'}/>
            </div>
            <div className={'frag5'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Могу ли я обратиться в GoTem_web только за дизайном сайта?'} answer={'Да, GoTem_web предлагает услуги по созданию уникального веб-дизайна отдельно. Мы создадим стильный и функциональный интерфейс, который подчеркнёт индивидуальность вашего бизнеса.'}/>
            </div>
            <div className={'frag6'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Предоставляете ли вы услуги техподдержки после создания сайта?'} answer={'Да, мы предлагаем услуги техподдержки и обслуживания сайта после его создания. Это включает в себя обновления, мониторинг, устранение ошибок и консультации.'}/>
            </div>
            <div className={'frag7'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Как GoTem_web помогает в продвижении сайтов в Омске?'} answer={'Мы используем проверенные стратегии локального SEO, чтобы ваш бизнес был виден для потенциальных клиентов в Омске. Это включает оптимизацию на Яндекс.Картах, создание качественного контента и увеличение вашей онлайн-видимости.'}/>
            </div>
            <div className={'frag8'} style={{transition:"all, 1s"}}>
                <MyQuest quest={'Предоставляете ли вы услуги по контекстной рекламе?'} answer={'Да, мы предоставляем услуги по настройке и управлению контекстной рекламой в Яндекс.Директ и других рекламных платформах, чтобы помочь вам привлечь целевую аудиторию.'}/>
            </div>

        </div>
    );
};

export default Quest;