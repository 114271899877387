import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery.ripples';
import './UnderwaterWorld.css'
import coral from '../../images/coral.png'


function UnderwaterWorld() {
    useEffect(() => {
        // Инициализация jQuery Ripples
        $('.imageBack').ripples({
            resolution: 768,
            perturbance: 0.02,
        });

        // Очистка эффекта при размонтировании компонента
        return () => {
            $('.imageBack').ripples('destroy');
        };
    }, []);

    const [cursorStyle, setCursorStyle] = useState({
        top: '-100px',
        left: '-100px',
        scale: 1,
    });

    useEffect(() => {
        const handleMouseMove = (e) => {
            const newScale = 1 + Math.abs(e.movementX + e.movementY) / 200; // Изменяем масштаб на основе движения
            setCursorStyle({
                top: `${e.clientY}px`,
                left: `${e.clientX}px`,
                scale: newScale,
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setMousePosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className='fishContainer' style={{
            overflowX: 'hidden'
        }}>
             <div style={{
                        position: "absolute",
                        top: 0,
                        height: "15vh",
                        width: '100vw',
                        background: '#212121',
                        zIndex: '1',
                        display: 'grid',
                        justifyContent: 'center'
                    }}>
                        <button className={"buttonLega1"}><a href="/main"  style={{
                            color:'inherit',
                            width: '35vw',
                            height: '10vh',
                            borderRadius: '10vh',
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center',
                            }}>Нажми чтобы продолжить</a></button>
                    </div>
            <div className="imageBack">
                <div
                    className="custom-cursor"
                    style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(79,41,129,0)',
                        pointerEvents: 'none',
                        borderRadius: '50%',
                        transform: 'translate(-50%, -50%) scale(`${cursorStyle.scale}`)', // Центрируем и изменяем масштаб
                        top: cursorStyle.top,
                        left: cursorStyle.left,
                        transition: 'transform 0.1s ease', // Плавный переход
                        width: '20px',
                        height: '20px',
                    }}
                />
                <h1 className="title" style={{
                    transform: `translateX(-${mousePosition.x * 0.02}px) translateY(-${mousePosition.y * 0.02}px)`,
                }}>
                    Погрузитесь в <div className="water">глубину</div> открытий <br/> с нами
                </h1>
                <img src={coral} alt="" className='coral' style={{
                    transform: `translateX(-${mousePosition.x * 0.01}px) translateY(-${mousePosition.y * 0.01}px)`,
                }}/>
                <img src={coral} alt="" className='coral2' style={{
                    transform: `translateX(-${mousePosition.x * 0.01}px) translateY(-${mousePosition.y * 0.01}px)`,
                }}/>
            </div>
        </div>
    );
}

export default UnderwaterWorld;

