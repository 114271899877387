import React from 'react';
import cl from "./Loading.module.css"
import images from "../../images/arm.png";
import images2 from "../../images/shest.png";
import images3 from "../../images/book.png";
import images4 from "../../images/helpin.png";
import images1 from "../../images/inopl.png";

const Loading = () => {
    return (
        <div className={cl.container}>
            <div style={{
                display:"none"
            }}>
                <img src={images} alt=""/><img src={images2} alt=""/><img src={images3} alt=""/><img src={images4} alt=""/><img src={images1}
                                                                                                                                alt=""/>
            </div>
            <svg className={cl.pencil} viewBox="0 0 200 200" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="pencil-eraser">
                        <rect rx="5" ry="5" width="30" height="30"></rect>
                    </clipPath>
                </defs>
                <circle className={cl.pencil__stroke} r="70" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-dasharray="439.82 439.82" stroke-dashoffset="439.82" stroke-linecap="round"
                        transform="rotate(-113,100,100)"/>
                <g className={cl.pencil__rotate} transform="translate(100,100)">
                    <g fill="none">
                        <circle className={cl.pencil__body1} r="64" stroke="#702ACF" stroke-width="30"
                                stroke-dasharray="402.12 402.12" stroke-dashoffset="402" transform="rotate(-90)"/>
                        <circle className={cl.pencil__body2} r="74" stroke="#4f2981" stroke-width="10"
                                stroke-dasharray="464.96 464.96" stroke-dashoffset="465" transform="rotate(-90)"/>
                        <circle className={cl.pencil__body3} r="54" stroke="#4f2981" stroke-width="10"
                                stroke-dasharray="339.29 339.29" stroke-dashoffset="339" transform="rotate(-90)"/>
                    </g>
                    <g className={cl.pencil__eraser} transform="rotate(-90) translate(49,0)">
                        <g className={cl.pencil__eraserSkew}>
                            <rect fill="#4f2981" rx="5" ry="5" width="30" height="30"/>
                            <rect fill="#702ACF" width="5" height="30" clip-path="url(#pencil-eraser)"/>
                            <rect fill="hsl(223,10%,90%)" width="30" height="20"/>
                            <rect fill="hsl(223,10%,70%)" width="15" height="20"/>
                            <rect fill="hsl(223,10%,80%)" width="5" height="20"/>
                            <rect fill="hsla(223,10%,10%,0.2)" y="6" width="30" height="2"/>
                            <rect fill="hsla(223,10%,10%,0.2)" y="13" width="30" height="2"/>
                        </g>
                    </g>
                    <g className={cl.pencil__point} transform="rotate(-90) translate(49,-30)">
                        <polygon fill="#5d5d5d" points="15 0,30 30,0 30"/>
                        <polygon fill="#5d5d5d" points="15 0,6 30,0 30"/>
                        <polygon fill="#fc7c33" points="15 0,20 10,10 10"/>
                    </g>
                </g>
            </svg>
        </div>

    );
};

export default Loading;