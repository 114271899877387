import React from 'react';
import cl from './PORTIntro.module.css'
const PORTIntro = () => {
    return (
        <div className={cl.container}>
            <div className={cl.name}>
                <span>С</span><span>А</span><span>Й</span><span>Т</span><span> И</span><span>З</span><span> П</span><span>О</span><span>Р</span><span>Т</span><span>Ф</span><span>О</span><span>Л</span><span>И</span><span>О</span>
            </div>
        </div>
    );
};

export default PORTIntro;