import {useEffect, useState} from "react";

export function usePageLoaded() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout( () => {
                const handleLoad = () => {
                    setIsLoaded(true);
                };
                if (document.readyState === 'complete') {
                    setIsLoaded(true);
                } else {
                    window.addEventListener('load', handleLoad);
                    return () => {
                        window.removeEventListener('load', handleLoad);
                    };
                }
            },2200
        )
    }, []);

    return isLoaded;
}
