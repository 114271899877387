import React, {useLayoutEffect} from 'react';
import cl from './Problem.module.css'
import think from '../../../images/helpau.png'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Problem = () => {
    gsap.registerPlugin(ScrollTrigger)
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".imagination",
                scrub: true,
                start: "0% bottom",
                end: "20% 50%",
            }
        })

        tl.from(".imagination", {
            y: 500,
        }).to(".imagination",
            {
                y:0,
            }
        );
        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".right",
                scrub: true,
                start: "-45% bottom",
                end: "-20% 50%",
            }
        })

        au.from(".right", {
            x: 900,
        }).to(".right",
            {
                x:0,
            }
        );


    }, []);
    return (
        <div className={cl.container}>
            <div className={cl.left}>
                <img src={think} alt="человек хочет купить сайт под ключ в омске" className={"imagination"} onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
            <div className={cl.rightCont}>
                <p className={'right'}>
                    ЕСЛИ ВЫ НЕ НАШЛИ ОТВЕТ НА <div>ВАШ ВОПРОС,</div> ВЫ МОЖЕТЕ СВЯЗАТЬСЯ С <div>НАШИМ МЕНЕДЖЕРОМ. </div>
                    ДЛЯ ЭТОГО <div>ОСТАВЬТЕ ЗАЯВКУ</div> ВНИЗУ СТРАНИЦЫ ИЛИ СВЯЖИТЕСЬ С НИМ САМОСТОЯТЕЛЬНО ПО ССЫЛКАМ УКАЗАННЫМ НИЖЕ.
                </p>
            </div>

        </div>
    );
};

export default Problem;