import React from 'react';
import MyFooter from "../components/footer/MyFooter";
import MyHeader from "../components/header/MyHeader";
import TeamIntro from "../components/teamPage/intro/TeamIntro";
import CartContainer from "../components/teamPage/contcart/CartContainer";
import Description from "../components/teamPage/descr/Description";
import Loading from "../components/loading/Loading";
import {usePageLoaded} from "../components/usepageloaded/usePageLoaded";
import { Helmet } from "react-helmet";

const Team = () => {
    const isLoaded = usePageLoaded();

    return (
        <>
            {isLoaded ? (
                <div style={{
                    overflow: "hidden"
                }}>
                    <Helmet>
                        <title>GoTem_web - команда</title>
                        <meta name="description" content="Увеличьте продажи и поток клиентов с нашей веб-студией! Мы создаем сайты и дизайны, которые работают на ваш успех. Доверьтесь профессионалам." />
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "WebSite",
                                "name": "GoTem_web: веб-студия для увеличения продаж и клиентов",
                                "url": "https://gotem-web.ru"
                            })}
                        </script>
                    </Helmet>
                    <MyHeader/>
                    <TeamIntro/>
                    <Description/>
                    <CartContainer/>
                    <MyFooter/>
                </div>
            ) : (
                <Loading/>
            )}
        </>

    );
};

export default Team;