import React from 'react';
import cl from './Links.module.css'

const Links = () => {
    return (
            <div className={cl.container}>
                <a href={'/about'} className={cl.block}>
                    О нас
                </a>
                <a href={'/portfolio'} className={cl.block}>
                    Портфолио
                </a>
                <a href={'/services'} className={cl.block}>
                    Услуги
                </a>
            </div>
    );
};

export default Links;