import React, {useEffect, useLayoutEffect, useState} from 'react';
import Modal from 'react-modal';
import  cl from './MyCalc.module.css'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const MyCalc = () => {



    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [display, setDisplay] = useState("grid")

    const openModal = () => {
        setModalIsOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setModalIsOpen(false);
        document.body.style.overflowY = 'auto';
    };
    const customStyles = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(91, 91, 91, 0.75)'
        },
    };

    const [type1, setType1] = useState(false);
    const [type2, setType2] = useState(false);
    const [type3, setType3] = useState(false);
    const [value, setValue] = useState(1);
    const [adapt, setAdapt] = useState(false);
    const [cross, setCross] = useState(false);
    const [obr, setObr] = useState(false);
    const [kon, setKon] = useState(false);
    const [po, setPo] = useState(false);
    const [fil, setFil] = useState(false);
    const [opl, setOpl] = useState(false);
    const [reg, setReg] = useState(false);
    const [host, setHost] = useState(false);

    useEffect(() => {

        if (type1){
            setDisplay("none")
            setValue(1)
        }else{
            setDisplay("grid")
        }
        if (type3){
            setOpl(true)
        }else {
            setOpl(false)
        }
    }, [
        type1, type2, type3
    ])

    const handleLandingChange = () => {
        setType1(true);
        setType2(false);
        setType3(false);
    };
    const handleMultiPageChange = () => {
        setType1(false);
        setType2(true);
        setType3(false);
    };
    const handleEcommerceChange = () => {
        setType1(false);
        setType2(false);
        setType3(true);
    };
    const [cost, setCost] = useState(0);
    const calc = (e) => {
        e.preventDefault()
        let x = 0;
        if(type1){
            console.log("1")
            x=x+25000
            if (adapt){
                x=x+8000;
            }
            if (cross){
                x=x+3000;
            }
            if(opl){
                x=x+3000
            }
            if(reg){
                x=x+20000;
            }
            if(po){
                x=x+15000
            }
            if(fil){
                x=x+15000
            }
            if(kon){
                x=x+30000
            }
            if(obr){
                x=x+15000
            }
            if(host){
                x=x+3000
            }
        }
        else if(type2){
            x=0;
            switch (value){
                case 1:
                    console.log(1)
                    x=x+25000
                    break;
                case 2:
                    console.log(2)
                    x=x+45000
                    break;
                case 3:
                    console.log(3)
                    x=x+60000
                    break;
                case 4:
                    console.log(4)
                    x=x+75000
                    break;
                case 5:
                    console.log(5)
                    x=x+90000
                    break;
                case 6:
                    console.log(6)
                    x=x+105000
                    break;
                default:
                    console.log(22)
                    x=x+105000+value*11000
                    break;
            }
            if (adapt){
                switch (value){
                    case 1:
                        x=x+8000
                        break;
                    case 2:
                        x=x+13000
                        break;
                    case 3:
                        x=x+18000
                        break;
                    case 4:
                        x=x+22000
                        break;
                    case 5:
                        x=x+25000
                        break;
                    case 6:
                        x=x+28000
                        break;
                    default:
                        console.log(78)
                        x=x+28000+value*3000
                }
            }
            if (cross){
                switch (value){
                    case 1:
                        x=x+4000
                        break;
                    case 2:
                        x=x+6000
                        break;
                    case 3:
                        x=x+8000
                        break;
                    case 4:
                        x=x+10000
                        break;
                    case 5:
                        x=x+12000
                        break;
                    case 6:
                        x=x+14000
                        break;
                    default:
                        console.log(56)
                        x=x+15000
                }
            }
            if(opl){
                x=x+3000
            }
            if(reg){
                x=x+20000;
            }
            if(po){
                x=x+15000
            }
            if(fil){
                x=x+15000
            }
            if(kon){
                x=x+30000
            }
            if(obr){
                x=x+15000
            }
            if(host){
                x=x+3000
            }
        }
        else{
            x=0
            console.log("3")
            switch (value){
                case 1:
                    x=x+35000
                    break;
                case 2:
                    x=x+60000
                    break;
                case 3:
                    x=x+75000
                    break;
                case 4:
                    x=x+90000
                    break;
                case 5:
                    x=x+105000
                    break;
                case 6:
                    x=x+120000
                    break;
                default:
                    x=x+120000+value*12000
            }
            if (adapt){
                switch (value){
                    case 1:
                        x=x+8000
                        break;
                    case 2:
                        x=x+13000
                        break;
                    case 3:
                        x=x+18000
                        break;
                    case 4:
                        x=x+22000
                        break;
                    case 5:
                        x=x+25000
                        break;
                    case 6:
                        x=x+28000
                        break;
                    default:
                        x=x+28000+value*3000
                }
            }
            if (cross){
                switch (value){
                    case 1:
                        x=x+4000
                        break;
                    case 2:
                        x=x+6000
                        break;
                    case 3:
                        x=x+8000
                        break;
                    case 4:
                        x=x+10000
                        break;
                    case 5:
                        x=x+12000
                        break;
                    case 6:
                        x=x+14000
                        break;
                    default:
                        x=x+15000
                }
            }
            if(reg){
                x=x+20000;
            }
            if(po){
                x=x+15000
            }
            if(fil){
                x=x+15000
            }
            if(kon){
                x=x+30000
            }
            if(obr){
                x=x+15000
            }
            if(host){
                x=x+3000
            }
        }
        setCost(x)
    }


    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".costCalc",
                scrub: true,
                start: "-50% bottom",
                end: "40% bottom",
            }
        })
        tl.from(".costCalc", {
            y: 500,
        }).to(".costCalc",
            {
                y: 0,
            }
        );

    }, []);
    return (
        <div className={cl.container}>
                    <button onClick={openModal}>Пройти тест за 45 секунд и узнать стоимость сайта</button>
                <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={closeModal} className={cl.modal}>
                    <div className={cl.containerCalc}>
                        <form action="">
                        <div>
                            <div>
                                <div className={cl.nameModal}>Тип сайта</div>
                                <div className={cl.closer}>
                                    <label htmlFor={'type1'} className={cl.customRadio}>
                                        <input type="radio" name={'type'} id={'type1'}
                                               onChange={handleLandingChange}
                                               checked={type1}
                                        />
                                        <span>Лендинг</span>
                                    </label>
                                    <label htmlFor={'type2'} className={cl.customRadio}>
                                        <input type="radio" name={'type'} id={'type2'} onChange={handleMultiPageChange} checked={type2}/>
                                        <span>Многостраничный</span>
                                    </label>
                                    <label htmlFor={'type3'} className={cl.customRadio1} style={{
                                        whiteSpace:"nowrap"
                                    }}>
                                        <input type="radio" name={'type'} id={'type3'} onChange={handleEcommerceChange} checked={type3}/>
                                        <span style={{
                                            whiteSpace:"nowrap"
                                        }}>Интернет-магазин</span>
                                    </label>
                                    <div className={cl.cross}>
                                        <div onClick={closeModal} className={cl.real}>
                                            ✖
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: `${display}`
                            }}>
                                <div className={cl.nameModal}>Количество страниц</div>
                                <div className={cl.numContainer}>
                                    <input type="range" id="number" name="number" min="1" max="100" step="1" className={cl.numberStr} value={value} onChange={ (event) => setValue(Number(event.target.value))}/>
                                    <div className={cl.numValue}>{value}</div>
                                </div>
                            </div>
                        </div>
                        <div className={cl.design}>
                            <div className={cl.nameModal}>
                                <span>Дизайн</span>
                            </div>
                            <div className={cl.desCont}>
                                <label htmlFor={'design1'} className={cl.customCheckbox}>
                                    <input checked={adapt} type="checkbox" id={'design1'} name={'design'} onChange={() => setAdapt(!adapt)}/>
                                    <span>Адаптивность</span>
                                </label>
                                <label htmlFor={'design2'} className={cl.customCheckbox}>
                                    <input checked={cross} type="checkbox" id={'design2'} name={'design'} onChange={() => setCross(!cross)}/>
                                    <span>Кроссбраузерность</span>
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className={cl.nameModal}>Функционал</div>
                            <div className={cl.funcCont}>
                                <label htmlFor={'f1'} className={cl.customCheckbox}>
                                    <input checked={obr} type="checkbox" name={'functions'} id={'f1'} onChange={() => setObr(!obr)}/>
                                    <span>Формы обратной связи</span>
                                </label>
                                <label htmlFor={'f2'} className={cl.customCheckbox}>
                                    <input checked={kon} type="checkbox" name={'functions'} id={'f2'} onChange={() => setKon(!kon)}/>
                                    <span>Онлайн консультанты</span>
                                </label>
                                <label htmlFor={'f3'} className={cl.customCheckbox}>
                                    <input checked={po} type="checkbox" name={'functions'} id={'f3'} onChange={() => setPo(!po)}/>
                                    <span>Поисковые системы</span>
                                </label>
                                <label htmlFor={'f4'} className={cl.customCheckbox}>
                                    <input checked={fil} type="checkbox" name={'functions'} id={'f4'} onChange={() => setFil(!fil)}/>
                                    <span>Фильтры и сортировка</span>
                                </label>
                                <label htmlFor={'f5'} className={cl.customCheckbox}>
                                    <input type="checkbox" name={'functions'} id={'f5'} onChange={() => setOpl(!opl)} checked={opl}/>
                                    <span>Форма оплаты</span>
                                </label>
                                <label htmlFor={'f6'} className={cl.customCheckbox}>
                                    <input checked={reg} type="checkbox" name={'functions'} id={'f6'} onChange={() => setReg(!reg)}/>
                                    <span>Регистрация аккаунтов</span>
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className={cl.nameModal}>Сопровождение</div>
                            <label htmlFor={'h1'} className={cl.customCheckbox}>
                                <input checked={host} type="checkbox" name={'hosting'} id={'h1'} onChange={() => setHost(!host)}/>
                                <span>Хостинг</span>
                            </label>
                        </div>
                            <br/>
                            <div className={cl.newContainer}>
                                <div className={cl.nameModal2}>Стоимость: {cost}</div>
                                <button onClick={calc}>Рассчитать</button>
                            </div>

                        </form>
                    </div>
                </Modal>
        </div>
    );
};

export default MyCalc;