import React from 'react';
import cl from './TeamIntro.module.css'
const TeamIntro = () => {
    return (
        <div className={cl.container}>
            <div className={cl.name}>
                <span>Н</span><span>А</span><span>Ш</span><span>А   </span>
                <span>      К</span><span>О</span><span>М</span><span>А</span><span>Н</span><span>Д</span><span>А</span>
            </div>
        </div>
    );
};

export default TeamIntro;