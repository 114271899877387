import {React, useLayoutEffect} from 'react';
import cl from '../PortMain.module.css'
import banana from '../../../../portfolio/Smaulik1.2.jpg'
import credit from '../../../../portfolio/sammy4.2.jpg'
import future from '../../../../portfolio/Smaulik5.2.jpg'
import dagestan from '../../../../portfolio/profilo1.2.jpg'
import homes from '../../../../portfolio/Smaulik6.2.jpg'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const PortPart = () => {
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".block",
                scrub: true,
                start: "-20% bottom",
                end: "-5% 50%",
            }
        })

        tl.from(".block", {
            y: 500,
        }).to(".block",
            {
                y:0,
            }
        );
    }, []);
    return (
        <div className={'block'}>
            <div className={cl.name}>
                Портфолио
            </div>
            <div className={cl.portContainer}>
                <a className={cl.portBlock} href="/work1" target={'_blank'}>
                    <div className={cl.portName}>Mirafrut</div>
                    <img src={banana} alt="" onContextMenu={handleContextMenu} draggable={"false"} />
                </a>
                    <a className={cl.portBlock} href="/work2" target={'_blank'}>
                        <div className={cl.portName}>Expred</div>
                        <img src={credit} alt="" onContextMenu={handleContextMenu} draggable={"false"} />
                    </a>
                    <a className={cl.portBlock} href="/work3" target={'_blank'}>
                        <div className={cl.portName}>ШТИ</div>
                        <img src={future} alt="" onContextMenu={handleContextMenu} draggable={"false"} />
                    </a>
                    <a className={cl.portBlock} href="/work4" target={'_blank'}>
                        <div className={cl.portName}>Туроператор OpenDagestan</div>
                        <img src={dagestan} alt="" onContextMenu={handleContextMenu} draggable={"false"} />
                    </a>
                    <a className={cl.portBlock} href="/work5" target={'_blank'}>
                        <div className={cl.portName}>Строительная компания</div>
                        <img src={homes} alt="" onContextMenu={handleContextMenu} draggable={"false"} />
                    </a>
            </div>
        </div>
    );
};

export default PortPart;