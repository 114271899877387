import React, {useEffect, useState} from 'react';
import cl from "./MyHeader.module.css";
import logo from '../../images/logo.png'

const MyHeader = () => {
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    const [isOpen, setOpen] = useState(false)
    const [state,setState] = useState(-110)
        useEffect(() => {
            if (isOpen){
                setState(0)
            }else{
                setState(-110)
            }
        }, [isOpen])
    return (
        <div className={cl.header}>
            <div className={cl.left}>
                <div className={cl.logo}>
                    <img src={logo} alt="" onContextMenu={handleContextMenu} draggable={"false"}/>
                </div>
                <a href={'/'} className={cl.name}>
                    GoTem_web
                </a>
                <button className={cl.headerMenu} onClick={() => setOpen(!isOpen)} style={{
                    background: "#212121",
                    border: "none",
                    boxShadow: 'none'
                }}>
                    <svg width="64px" height="64px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#fc7c33" stroke-width="0.528"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fc7c33" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </button>
            </div>
            <div className={cl.right}>
                <ul className={cl.adaptMenu}
                    style={{
                        marginTop: `${state}vh`
                    }}
                >
                    <li><a href="/about">О нас</a></li>
                    <li><a href="/services">Услуги</a></li>
                    <li><a href="/portfolio">Портфолио</a></li>
                    <li><a href="/help">Помощь</a></li>
                </ul>
            </div>
        </div>
    );
};

export default MyHeader;