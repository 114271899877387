import React, {useLayoutEffect} from 'react';
import './CartContainer.css'
import MyName from "../../name/MyName";
import MyCart from "../../mycart/MyCart";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import one from '../../../images/1.webp'
import two from '../../../images/2.webp'
import three from '../../../images/3.webp'
import four from '../../../images/4.webp'
import five from '../../../images/5.webp'
const CartContainer = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {

        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".cart",
                scrub: true,
                start: "-70% bottom",
                end: "-70% 50%",
            }
        })

        au.from(".cart", {
            y: 500,
        }).to(".cart", {
                y:0,
            });

        const tau = gsap.timeline({
            scrollTrigger: {
                trigger: ".cart1",
                scrub: true,
                start: "-40% bottom",
                end: "-40% 50%",
            }
        })

        tau.from(".cart1", {
            y: 500,
        }).to(".cart1", {
            y:0,
        });

        const pau = gsap.timeline({
            scrollTrigger: {
                trigger: ".cart2",
                scrub: true,
                start: "-40% bottom",
                end: "-40% 50%",
            }
        })

        pau.from(".cart2", {
            y: 500,
        }).to(".cart2", {
            y:0,
        });

        const krau = gsap.timeline({
            scrollTrigger: {
                trigger: ".cart3",
                scrub: true,
                start: "-60% bottom",
                end: "-60% 50%",
            }
        })

        krau.from(".cart3", {
            y: 500,
        }).to(".cart3", {
            y:0,
        });

        const vau = gsap.timeline({
            scrollTrigger: {
                trigger: ".cart4",
                scrub: true,
                start: "-10% bottom",
                end: "-10% 50%",
            }
        })

        vau.from(".cart4", {
            y: 500,
        }).to(".cart4", {
            y:0,
        });


    }, []);
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div className={"container"}>
            <br/>
            <br/>
            <br/>
            <MyName name={'Веб-разработка'}/>
            <div className="cart">
                <MyCart name={"Андрей"} description={'Выдающийся мастер лендинговых страниц, который вдохновляет клиентов своим креативным подходом и безупречным качеством исполнения. Имея 8 летний опыт работы, он сможет взяться за заказ любой сложности.'} image={ <img src={two} alt="" onContextMenu={handleContextMenu} draggable={"false"}/>}/>
            </div>
            <div className="cart1">
                <MyCart name={"Максим"} description={'Универсальный веб-разработчик, чьим талантом можно восхищаться без конца. Неважно, с каким проектом он работает: корпоративный сайт, блог или портал — его работы всегда выделяются стабильностью, функциональностью и эстетическим совершенством.'} image={ <img src={five} alt="" onContextMenu={handleContextMenu} draggable={"false"}/>}/>
            </div>
            <div className="cart2">
                <MyCart name={"Руслан"} description={'Непревзойденный эксперт в создании интернет-магазинов, превращающий каждую идею в высокоприбыльную онлайн-платформу. Его 18-ти летний опыт позволит ему воплотить вашу мечту в реальность.'} image={ <img src={one} alt="" onContextMenu={handleContextMenu} draggable={"false"}/>}/>
            </div>
            <MyName name={'Веб-дизайн'}/>
            <div className="cart3">
                <MyCart name={"Диана"} description={'Выдающийся дизайнер, чей изысканный вкус и безупречное чувство стиля видны в каждой работе. Ее способность превращать клиентские идеи в визуальные шедевры просто восхищает и оставляет неизгладимое впечатление на аудиторию.'} image={ <img src={four} alt="" onContextMenu={handleContextMenu} draggable={"false"}/>}/>
            </div>
            <MyName name={'Телеграм-боты'}/>
            <div className="cart4">
                <MyCart name={"Артем"} description={'Истинный маг в мире Telegram ботов, чьи разработки поражают своей функциональностью и пользовательским опытом. Его креативные решения и практичный подход делают каждый бот не только полезным, но и удивительно простым в использовании.'} image={ <img src={three} alt="" onContextMenu={handleContextMenu} draggable={"false"}/>}/>
            </div>
        </div>
    );
};

export default CartContainer;