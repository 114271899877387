import React from 'react';
import MyHeader from "../components/header/MyHeader";
import MyFooter from "../components/footer/MyFooter";
import PortIntro from "../components/portPage/portIn/PortIntro";
import MyTech from "../components/technologies/MyTech";
import PortMain from "../components/portPage/portMain/PortMain";
import MyName from "../components/name/MyName";
import AboutTeam from "../components/aboutPage/team/AboutTeam";
import Loading from "../components/loading/Loading";
import {usePageLoaded} from "../components/usepageloaded/usePageLoaded";
import { Helmet } from "react-helmet";


const Portfolio = () => {
    const isLoaded = usePageLoaded();

    return (
        <>
            <Helmet>

            <title>Сайты любой сложности под ключ | веб-студия GoTem_web</title>

            <meta name="description" content="Создание сайтов под ключ любой сложности быстро, качественно и недорого" />

            <link rel="canonical" href="https://gotem-web.ru/portfolio" />

            <link rel="icon" type="image/png" href="../../public/favicon.png"/>
            </Helmet>
            {isLoaded ? (
                <div style={{
                    overflow: "hidden"
                }}>

                    <MyHeader/>
                    <PortIntro/>
                    <PortMain/>
                    <MyTech/>
                    <MyName name={'Команда'}/>
                    <AboutTeam/>
                    <MyFooter/>
                </div>
            ) : (
                <Loading/>
            )}
        </>

    );
};

export default Portfolio;