import React, {useLayoutEffect} from 'react';
import cl from "./SerIn.module.css";
import planet from '../../../images/pc.png'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const SerIn = () => {
    gsap.registerPlugin(ScrollTrigger)
    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".image",
                scrub: true,
                start: "0% bottom",
                end: "20% 50%",
            }
        })

        tl.from(".image", {
            y: 500,
        }).to(".image",
            {
                y:0,
            }
        );
        const au = gsap.timeline({
            scrollTrigger: {
                trigger: ".left",
                scrub: true,
                start: "-55% bottom",
                end: "-21% 50%",
            }
        })

        au.from(".left", {
            x: 900,
        }).to(".left",
            {
                x:0,
            }
        );


    }, []);
    return (
        <div className={cl.container}>
            <div className={cl.right}>
                <img src={planet} alt="компьютер для содания сайтов под ключ в Омске" className={"image"} onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
            <div className={cl.leftCont}>
                <div className={"left"}>
                    Веб-студия <div>GoTem_web</div> предлагает комплексные услуги для успеха в интернете – от создания сайта до его продвижения. Мы стремимся не только выполнить задачу, но и стать вашим <div>надежным партнером</div> в дальнейшем росте вашего бизнеса, обеспечивая постоянную поддержку и рост вашего проекта.
                </div>
            </div>
        </div>
    );
};

export default SerIn;