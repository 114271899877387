import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import BeeSVG from '../../images/bee.png';

const Container = styled.div`
    width: 100vw;
    height: 120vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd73f;
`;

const Honeycomb = styled.svg`
    width: 100%;
    height: 100%;
    filter: blur(4px);
`;

const generateRandomColor = () => {
    const colors = ['#ffd73f', '#ffc400', '#ffd500', '#ffc031'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
};

const Cell = styled.path`
    fill: ${props => props.fillColor};
    stroke: #ffdd00;
    stroke-width: 3px;
    transition: all 0.9s ease-in;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
`;

const beeFly = keyframes`
    from {
        left: -25%;
    }
    to {
        left: 110%;
    }
`;

const Bee = styled.img`
    position: absolute;
    width: ${props => props.size}px;
    height: auto;
    animation: ${beeFly} ${props => props.duration}s linear infinite;
    top: ${props => props.top}%;
    transition: transform 0.9s ease;
`;

const BeeBackPhone = () => {
    const [colors, setColors] = useState([]);

    useEffect(() => {
        const generateColors = () => {
            const sideLength = 50;
            const dx = sideLength * Math.sqrt(3);
            const dy = sideLength * 1.5;
            const rows = Math.ceil(window.innerHeight / dy);
            const cols = Math.ceil(window.innerWidth / dx);
            const colorsArray = [];

            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    colorsArray.push(generateRandomColor());
                }
            }
            setColors(colorsArray);
        };

        generateColors();
    }, []);

    const generateCells = () => {
        const cells = [];
        const sideLength = 50;
        const dx = sideLength * Math.sqrt(3);
        const dy = sideLength * 1.5;
        const rows = Math.ceil(window.innerHeight / dy);
        const cols = Math.ceil(window.innerWidth / dx);
        let colorIndex = 0;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                const x = col * dx + (row % 2) * (dx / 2);
                const y = row * dy;

                const d = `
                    M ${x + dx / 2}, ${y}
                    L ${x + dx}, ${y + sideLength / 2} 
                    L ${x + dx}, ${y + sideLength * 1.5} 
                    L ${x + dx / 2}, ${y + sideLength * 2} 
                    L ${x}, ${y + sideLength * 1.5} 
                    L ${x}, ${y + sideLength / 2} 
                    Z
                `;

                cells.push(
                    <Cell
                        key={`${row}-${col}`}
                        d={d}
                        fillColor={colors[colorIndex++]}
                    />
                );
            }
        }
        return cells;
    };

    const generateBees = () => {
        const bees = [];
        const beeCount = 20;

        for (let i = 0; i < beeCount; i++) {
            const size = Math.random() * 40 + 20;
            const duration = Math.random() * 2 + 0.7;
            const top = Math.random() * 100;

            bees.push(
                <Bee
                    src={BeeSVG}
                    size={size}
                    duration={duration}
                    top={top}
                    key={i}
                />
            );
        }
        return bees;
    };

    return (
        <Container>
            <Honeycomb>{generateCells()}</Honeycomb>
            <div>
                {generateBees()}
            </div>
        </Container>
    );
};

export default BeeBackPhone;
