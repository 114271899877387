import React, {useEffect, useRef, useState} from "react";
import UnderwaterWorld from "../ui components/mySlider/UnderwaterWorld";
import BeeWorld from "../ui components/mySlider/BeeWorld";
import Heaven from "../ui components/mySlider/Heaven";
import cl from './Main.module.css';
import MainPhone from "./MainPhone";




function MainFirst() {
    const components = [<UnderwaterWorld />, <BeeWorld />, <Heaven />];
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalDuration = 1000000; // 30 секунд
    useEffect(() => {
        if(width>1024){
            document.body.style.overflow = 'hidden';
        }
        // Добавляем стиль для блокировки прокрутки при монтировании компонента
        
    
        // Возвращаем функцию, которая снимает этот стиль при размонтировании компонента
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, []);
    useEffect(() => {
        // Запуск таймера для обновления индекса
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
        }, intervalDuration);

        // Очищаем интервал при размонтировании
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleSlideChange = (index) => {
        setCurrentIndex(index);
    };

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
  
      // Добавляем обработчик события изменения размера окна
      window.addEventListener("resize", handleResize);
  
      // Удаляем обработчик при размонтировании компонента
      return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <div>
{width < 1025 
            ? <MainPhone/> 

            : 
            <div>
            <div className={cl.mainSlider}>
                <div style={{
                    overflow: 'hidden',
                    height: '100vh'
                }}>{components[currentIndex]}</div>
                <div className={cl.menu}>
                    <div style={{
                        backgroundColor: '#5d5d5d',
                        height: '2vh',
                        position: 'relative',
                        top: '6vh',
                        borderRadius: '4vh',
                        width: '80%',
                        overflow: 'hidden',
                        margin: 'auto'
                    }}>
                        <div key={currentIndex} style={{
                            backgroundColor: '#fc7c33',
                            height: '100%',
                            animation: `progress ${intervalDuration}ms linear infinite`
                        }}/>
                    </div>

                    <style jsx>{`
                        @keyframes progress {
                            0% {
                                width: 0%;
                            }
                            100% {
                                width: 100%;
                            }
                        }
                    `}</style>
<div style={{display: 'flex', justifyContent: 'center',}}>
    {components.map((_, index) => (
        <div
            key={index}
            onClick={() => handleSlideChange(index)}
            style={{
                cursor: 'pointer',
                height: '10px',
                borderRadius: '10px',
                margin: '0 5px',
                backgroundColor: currentIndex === index ? '#4f2981' : '#5d5d5d',
                transition: 'background-color 0.3s ease',
                width: '80px'
            }}
        />
    ))}
</div>
</div>
            </div>
        </div>}
        </div>
    )
        ;
}

export default MainFirst;
